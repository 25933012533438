import { Space, Pagination } from "antd";
import { useEffect, useState } from "react";
import { commonService } from "../../API";
import AppHeader from "../../Components/AppHeader";
import { useNavigate } from "react-router-dom";
import Carousel from "react-grid-carousel";
import "./style.scss";
import Loader from "../../Components/Loader";
import AppFooter from "../../Components/AppFooter";
import { Helmet } from "react-helmet";
function Reports() {
  const [loading, setLoading] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [displayedData, setDisplayedData] = useState([]);

  const navigate = useNavigate();

  const fetchData = async () => {
    setLoading(true);
    const reportsList = await commonService.getReportsList({
      page: 0,
      limit: 5000000,
    });
    setDataSource(reportsList?.data?.reports);
    onChangePage(1, 10, reportsList?.data?.reports);
    setLoading(false);
  };
  const onChangePage = (page, pageSize, data) => {
    const startItem = (page - 1) * pageSize;
    const endItem = page * pageSize;
    const updatedData = data || dataSource;
    const paginatedData = updatedData.slice(startItem, endItem);
    setDisplayedData(paginatedData);
  };
  useEffect(() => {
    try {
      fetchData();
    } catch {
      setLoading(false);
    }
  }, []);

  return (
    <Space size={20} direction="vertical">
      {loading ? (
        <Loader />
      ) : (
        <div className="news reports">
          <Helmet>
            <title>Reports - Radical Watch </title>
          </Helmet>
          <AppHeader />
          <div className="news_wrapper">
            <div className="heading_wrapper font-face-Bold">
              <p> Reports</p>
            </div>
            <div className="carousel">
              <Carousel
                cols={3}
                rows={1}
                gap={10}
                loop
                // showDots
                dotColorActive={"#00AD45"}
                autoplay={4000}
              >
                {dataSource?.map((item) => {
                  return (
                    <Carousel.Item>
                      <div
                        className="postContainer"
                        onClick={() => {
                          navigate(`/reports/detail/${item?._id}`);
                        }}
                      >
                        <img src={item?.logo} alt="logo" />
                        <p className="newsTitle">{item?.title}</p>
                      </div>
                    </Carousel.Item>
                  );
                })}
              </Carousel>
            </div>
            <div className="news_grid">
              {displayedData?.map((item, index) => {
                return (
                  <div
                    className={`postContainer item${index}`}
                    onClick={() => {
                      navigate(`/reports/detail/${item?._id}`);
                    }}
                  >
                    <img src={item?.logo} alt="logo" />
                    <p className="newsTitle">{item?.title}</p>
                  </div>
                );
              })}
            </div>
            <Pagination
              simple
              defaultCurrent={1}
              onChange={onChangePage}
              total={dataSource?.length}
            />
          </div>
          <AppFooter />
        </div>
      )}
    </Space>
  );
}
export default Reports;
