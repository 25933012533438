import { Space, Input, Table, DatePicker } from "antd";
import { useEffect, useState } from "react";
import AppHeader from "../../Components/AppHeader";
import DropDown from "../../Components/DropDown";
import {
  casualFactorsArr,
  dataBaseColumns,
  natureOfViolanceArr,
  victimsReligonArr,
} from "../../Constants/mockData";
import { SearchOutlined, EyeOutlined } from "@ant-design/icons";
import "./style.scss";
import { commonService } from "../../API";
import moment from "moment";
import { ReactComponent as ArrowBothIcon } from "../../Assets/arrow-both.svg";
import { ReactComponent as Toggler } from "../../Assets/toggler.svg";
import AppFooter from "../../Components/AppFooter";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import RestartAltSharpIcon from '@mui/icons-material/RestartAltSharp';
function Database() {
  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [districtList, setDistrictList] = useState([]);
  const [state, setState] = useState(null);
  const [district, setDistrict] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [natureOfViolence, setNatureOfViolence] = useState(null);
  const [casualFactors, setCasualFactors] = useState(null);
  const [search, setSearch] = useState(null);
  const [identityOfVictim, setidentityOfVictim] = useState(null);
  const [collapsed, setCollapsed] = useState(true);
  const [resetFilters, setResetFilters]=useState(false)
  const navigate = useNavigate();
  const fetchData = async (payload = { limit: 600 }) => {
    setLoading(true);
    const newsList = await commonService.getNewsList(payload);
    setTableData(manipulateTableData(newsList?.data?.news));
    setLoading(false);
  };
  const getStateList = async () => {
    const stateList = await commonService.getStateList();
    setStateList(stateList?.data?.states);
  };
  const onStateChange = async (value, option) => {
    setState(value);
    fetchData({ states: value, search: search });
    const districtList = await commonService.getDistrictList({ id: value });
    setDistrictList(districtList?.data?.districts);
    setResetFilters(true)
  };
  const onInputChange = (e) => {
    setSearch(e.target.value);
    const payload = {
      search: e.target.value,
      states: state,
      districts: district,
      natureOfViolence: natureOfViolence,
      identityOfVictim: identityOfVictim,
      startDate: startDate,
      endDate: endDate,
      casualFactors: casualFactors,
      limit: 600,
    };
    fetchData(payload);
  };
  useEffect(() => {
    try {
      fetchData();
      getStateList();
    } catch {
      setLoading(false);
    }
  }, []);
  const getArrayOfObejct = (arr, name) => {
    return arr?.map((item, index) => {
      return { _id: index, [name]: item };
    });
  };
  const getAction = (item) => {
    return (
      <EyeOutlined
        onClick={() => {
          navigate(`/news/detail/${item?._id}`);
        }}
      />
    );
  };
  const manipulateTableData = (data) => {
    return data?.map((item, index) => {
      return {
        // key: index,
        serialNumber: index + 1,
        nameOfTheIncident: (
          <p
            onClick={() => {
              navigate(`/news/detail/${item?._id}`);
            }}
          >
            {item?.title || "- - -"}
          </p>
        ),
        date:
          moment(item?.keyInformation?.incidentDate).format("DD MMMM YYYY") ||
          "- - -",
        place: item?.district || "- - -",
        location: item?.otherInformation?.locationOfIncident || "- - -",
        state: item?.state || "- - -",
        victim: item?.keyInformation?.victimName || "- - -",
        numberOfVictims: item?.otherInformation?.numberOfVictim || "- - -",
        casualFactors: item?.keyInformation?.casualFactors || "- - -",
        natureOfViolence: item?.keyInformation?.natureOfVoilance || "- - -",
        action: getAction(item),
      };
    });
  };

  const onDistrictChange = async (value, option) => {
    setDistrict(value);
    fetchData({ states: state, districts: value });
    setResetFilters(true)
  };
  const onNatureOfViolenceChange = (value, option) => {
    setNatureOfViolence(option?.label);
    const payload = {
      states: state,
      districts: district,
      natureOfViolence: option?.label,
      identityOfVictim: identityOfVictim,
      casualFactors: casualFactors,
      startDate: startDate,
      endDate: endDate,
      search: search,
      limit: 600,
    };
    fetchData(payload);
    setResetFilters(true)
  };
  const onCasualFactorsChange = (value, option) => {
    setCasualFactors(option?.label);
    const payload = {
      states: state,
      districts: district,
      natureOfViolence: natureOfViolence,
      identityOfVictim: identityOfVictim,
      casualFactors: option?.label,
      startDate: startDate,
      endDate: endDate,
      search: search,
      limit: 600,
    };
    fetchData(payload);
    setResetFilters(true)
  };
  const onVictimsReligonChange = (value, option) => {
    setidentityOfVictim(option?.label);
    const payload = {
      states: state,
      districts: district,
      natureOfViolence: natureOfViolence,
      identityOfVictim: option?.label,
      casualFactors: casualFactors,
      startDate: startDate,
      endDate: endDate,
      search: search,
      limit: 600,
    };
    fetchData(payload);
    setResetFilters(true)
  };
  const onStartDateChange = (date, dateString) => {
    setStartDate(dateString);
    const payload = {
      states: state,
      districts: district,
      natureOfViolence: natureOfViolence,
      identityOfVictim: identityOfVictim,
      casualFactors: casualFactors,
      startDate: dateString,
      endDate: endDate,
      search: search,
      limit: 600,
    };
    fetchData(payload);
  };
  const onEndDateChange = (date, dateString) => {
    setEndDate(dateString);
    const payload = {
      states: state,
      districts: district,
      natureOfViolence: natureOfViolence,
      identityOfVictim: identityOfVictim,
      casualFactors: casualFactors,
      startDate: startDate,
      endDate: dateString,
      search: search,
      limit: 600,
    };
    fetchData(payload);
  };
  const resetFiltersHandler=()=>{
    fetchData();
    setResetFilters(false);
    setState(null);
    setDistrict(null);
    setNatureOfViolence(null);
    setCasualFactors(null);
    setidentityOfVictim(null);
  }
  return (
    <Space size={20} direction="vertical">
      <div className="news database">
        <Helmet>
          <title>Database - Radical Watch </title>
        </Helmet>
        <AppHeader />
        <div className="news_wrapper ">
          <div className="heading_wrapper font-face-Bold">
            <p> Database</p>
          </div>
          <div className="searchFilters">
            <div className="Filters">
              <p className="label">Search</p>
              <div className="filtersContainer">
                <div className="dropdowns">
                  <Input
                    placeholder="Search by the Name of the Victim / Title of the Incident"
                    suffix={<SearchOutlined />}
                    onChange={(e) => onInputChange(e)}
                    value={search}
                  />
                </div>
              </div>
            </div>
            <div className="calendar_container">
              <div className="calendar">
                <DatePicker
                  onChange={onStartDateChange}
                  placeholder="Start Date"
                  allowClear={false}
                  format="DD MMM YYYY"
                />
                <ArrowBothIcon />
                <DatePicker
                  onChange={onEndDateChange}
                  placeholder="End Date"
                  allowClear={false}
                  format="DD MMM YYYY"
                  minDate={startDate}
                />
              </div>
              <div
                className="filterButton"
                onClick={() => setCollapsed(!collapsed)}
              >
                <Toggler className={`toogler ${collapsed ? "" : "active"}`} />
              </div>
            </div>
          </div>

          <div
            className={`table_wrapper ${
              collapsed ? "tableNotCollapsed" : "tableCollapsed"
            } ${tableData?.length ? "" : "no_dataTable"}`}
          >
            <Table
              loading={loading}
              columns={dataBaseColumns}
              dataSource={tableData}
              className="font-face-regular"
              // pagination={{
              //   pageSize: 20,
              // }}
            ></Table>
            <div
              className={`${collapsed ? "filterCollapsed" : "filterOpened"}`}
            >
              <div className="filterHeader">
              <p className="label">Filters</p>
              <p className={`clearAll ${resetFilters ?"":"disabled"}`} onClick={resetFiltersHandler}>Reset <RestartAltSharpIcon/></p></div>
              <div className="filtersContainer">
                <div className="dropdowns ">
                  <DropDown
                    dropDownOptions={stateList}
                    name="state"
                    label="All States"
                    value={state}
                    handleChange={(value, option) =>
                      onStateChange(value, option)
                    }
                  />
                  {state && (
                    <DropDown
                      dropDownOptions={districtList}
                      name="district"
                      label="All Districts"
                      value={district}
                      handleChange={(value, option) =>
                        onDistrictChange(value, option)
                      }
                    />
                  )}
                  <DropDown
                    dropDownOptions={getArrayOfObejct(
                      natureOfViolanceArr,
                      "natureOfViolence"
                    )}
                    value={natureOfViolence}
                    name="natureOfViolence"
                    label="Nature of Violence"
                    handleChange={(value, option) =>
                      onNatureOfViolenceChange(value, option)
                    }
                  />
                  <DropDown
                    dropDownOptions={getArrayOfObejct(
                      casualFactorsArr,
                      "casualFactors"
                    )}
                    value={casualFactors}
                    name="casualFactors"
                    label="Causal Factors"
                    handleChange={(value, option) =>
                      onCasualFactorsChange(value, option)
                    }
                  />
                  <DropDown
                    dropDownOptions={getArrayOfObejct(
                      victimsReligonArr,
                      "victimsReligon"
                    )}
                    value={identityOfVictim}
                    name="victimsReligon"
                    label="Victims Religion"
                    handleChange={(value, option) =>
                      onVictimsReligonChange(value, option)
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <AppFooter />
    </Space>
  );
}
export default Database;
