import moment from "moment";
import Image1 from "../Assets/img1.svg";
import Image2 from "../Assets/img2.svg";
import Image3 from "../Assets/img3.svg";
import Image4 from "../Assets/img4.svg";

export const imageArray = [Image1, Image2, Image3, Image4];

export const getAppHeader = {
  news: {
    keyInformation: {
      incidentDate: "2023-05-11T00:00:00.000Z",
      casualFactors: "Religious Indentity",
      victimName: "Nisha Devi",
      peopleInjured: 1,
      natureOfVoilance: "Physical Assault",
      perpetratorName: "Muhammad Yaseen",
      state: "Uttar Pradesh",
      district: "Bareilly",
      partyInPower: "BJP",
    },
    _id: "645c7b55ff28886bff7b0b89",
    title:
      "Uttar Pradesh: Muslim doctor who murdered his Hindu second wife for not converting to Islam after marrying her pretending to be Hindu, gets life in jail",
  },
};

export const getHomeNewsList = {
  data: {
    news: [
      {
        _id: "646dd05203393e2a347e5f49",
        keyInformation: {
          victimName: ["Minor"],
          perpetratorName: [
            "Rubel Rehman",
            "Imran Hussain",
            "Mamunur Rehman",
            "Anowar Hussain",
          ],
          incidentDate: "2023-05-26T00:00:00.000Z",
          casualFactors: "Religious Indentity",
          peopleInjured: 1,
          natureOfViolance: "Rape",
          state: "Assam",
          district: "Kokrajhar",
          partyInPower: "BJP",
        },
        otherInformation: {
          charedFiled: true,
          sourceOfInformation: "News Report",
          identityOfPerpetrator: "Muslim",
          numberOfVictim: 1,
          locationOfIncident: "Road",
          perpetratorOrganization: "",
          investigationAgency: "The State Police (Assam)",
          roleOfPolice: "Satisfactory",
          judementReference: "",
          identityOfVictim: "Hindu",
          defailsOfFIR: "",
          crossFirFiled: 1,
          victimLawyer: "",
          currentStatus: "Open",
          remarks: "",
        },
        active: true,
        relatedNews: [],
        additionalResources: [],
        mediaLink: [
          {
            name: "OpIndia",
            link: "https://www.opindia.com/2023/05/assam-rubel-rehman-imran-mamunur-anowar-abduct-assault-gang-rape-13-year-old-moving-van",
          },
          {
            name: "India Today",
            link: "https://www.indiatodayne.in/assam/story/assam-13-year-old-girl-gang-raped-in-moving-car-in-kokrajhar-560056-2023-05-18",
          },
        ],
        isCrime: true,
        topNews: false,
        title:
          "13-year-old abducted, assaulted and gang raped in a moving van, Rubel Rehman, Imran, Mamunur, Anowar arrested",
        description:
          "A 13-year-old girl was gang-raped by four youths inside a moving car in the Kokrajhar district of Assam. The four accused have been identified as Rubel Rehman, Imran Hussain, Mamunur Rehman and Anowar Hussain all from Dhubri district. The Police have booked and arrested the accused under sections 363, 376(d), 328 of the Indian Penal Code and also under section 6 of the POCSO Act",
        logo: "https://i0.wp.com/www.opindia.com/wp-content/uploads/2023/05/721.png",
        media: [
          {
            _id: "646dd05203393e2a347e5f4a",
            url: "https://akm-img-a-in.tosshub.com/lingo/itne/images/story/202305/gang_rape-sixteen_nine.jpg",
          },
        ],
        stateId: "615ee13f72fd09f23833ca90",
        districtId: "615eeeb0e688dcbb54d4c959",
        victimSearch: "Minor",
        state: "Assam",
        district: "Kokrajhar",
        createdAt: "2023-05-24T08:52:34.687Z",
        updatedAt: "2023-05-24T08:52:34.687Z",
      },
      {
        _id: "646db79303393e2a347e5f3d",
        keyInformation: {
          victimName: ["Mamta"],
          perpetratorName: ["मोहम्मद हसन"],
          incidentDate: "2022-10-25T00:00:00.000Z",
          casualFactors: "Religious Indentity",
          peopleInjured: 1,
          natureOfViolance: "Physical Assault",
          state: "Uttar Pradesh",
          district: "Bareilly",
          partyInPower: "BJP",
        },
        otherInformation: {
          charedFiled: true,
          sourceOfInformation: "News18",
          identityOfPerpetrator: "Muslim",
          numberOfVictim: 1,
          locationOfIncident: "Aligarh",
          perpetratorOrganization: "",
          investigationAgency: "ADGC",
          roleOfPolice: "Investigation",
          judementReference: "",
          identityOfVictim: "Hindu",
          defailsOfFIR: "",
          crossFirFiled: 1,
          victimLawyer: "",
          currentStatus: "Open",
          remarks: "",
        },
        active: true,
        relatedNews: ["646db79303393e2a347e5f3d"],
        additionalResources: [],
        mediaLink: [
          {
            name: "News18",
            link: "https://hindi.news18.com/news/uttar-pradesh/aligarh-adopted-13-year-minor-hindu-daughter-raped-by-muslim-father-20-years-sentenced-6217117.html",
          },
        ],
        isCrime: true,
        topNews: false,
        title:
          "गोद ली हुई नाबालिग हिंदू बेटी के साथ मुस्लिम पिता ने किया रेप, मिली 20 साल की सजा",
        description:
          "“अलीगढ़. अलीगढ़ के थाना क्वार्सी इलाके के मंजूरगढ़ी में 13 वर्षीय नाबालिग हिंदू बेटी के साथ दुष्कर्म के दोषी कथित पिता को 20 वर्ष कैद की सजा सुनाई गई है. यह फैसला एडीजे विशेष पॉक्सो सुरेंद्र मोहन राय की अदालत से सुनाया गया है. बता दें कि बिन मां-बाप की बेटी इस घटना के बाद से नारी निकेतन में रह रही है.अभियोजन अधिवक्ता एडीजीसी महेश सिंह के बताए अनुसार, घटना 25 अक्टूबर 2020 की है. इलाके में ही रहने वाले एक सामाजिक व्यक्ति ने क्वार्सी में मुकदमा दर्ज कराते हुए बताया कि आरोपी 50 वर्षीय पप्पू और मोहम्मद हसन निवासी बिस्मिल्लाह कॉलोनी मंजूरगढ़ी में एक बिन मां-बाप की करीब 13 वर्षीय लड़की को गोद लिया था. घटना वाले दिन आरोपी की पुत्रवधू ने उसे बच्ची संग गंदी हरकत करते देख लिया और शोर मचा दिया.",
        logo: "https://www.google.com/url?sa=i&url=https%3A%2F%2Fcrimealert24x7.com%2F&psig=AOvVaw0eBNZUNbgM9VfXAbR7-i4e&ust=1684558390255000&source=images&cd=vfe&ved=0CBEQjRxqFwoTCIiG_ZXLgP8CFQAAAAAdAAAAABAE",
        media: [
          {
            _id: "646db79303393e2a347e5f3e",
            url: "https://www.google.com/url?sa=i&url=https%3A%2F%2Fcrimealert24x7.com%2F&psig=AOvVaw0eBNZUNbgM9VfXAbR7-i4e&ust=1684558390255000&source=images&cd=vfe&ved=0CBEQjRxqFwoTCIiG_ZXLgP8CFQAAAAAdAAAAABAE",
          },
        ],
        stateId: "615ee13f72fd09f23833ca89",
        districtId: "615eeeb0e688dcbb54d4ca18",
        victimSearch: "Mamta",
        state: "Uttar Pradesh",
        district: "Aligarh",
        createdAt: "2023-05-24T07:06:59.906Z",
        updatedAt: "2023-05-24T07:06:59.906Z",
      },
      {
        _id: "646dd05203393e2a347e5f49",
        keyInformation: {
          victimName: ["Minor"],
          perpetratorName: [
            "Rubel Rehman",
            "Imran Hussain",
            "Mamunur Rehman",
            "Anowar Hussain",
          ],
          incidentDate: "2023-05-26T00:00:00.000Z",
          casualFactors: "Religious Indentity",
          peopleInjured: 1,
          natureOfViolance: "Rape",
          state: "Assam",
          district: "Kokrajhar",
          partyInPower: "BJP",
        },
        otherInformation: {
          charedFiled: true,
          sourceOfInformation: "News Report",
          identityOfPerpetrator: "Muslim",
          numberOfVictim: 1,
          locationOfIncident: "Road",
          perpetratorOrganization: "",
          investigationAgency: "The State Police (Assam)",
          roleOfPolice: "Satisfactory",
          judementReference: "",
          identityOfVictim: "Hindu",
          defailsOfFIR: "",
          crossFirFiled: 1,
          victimLawyer: "",
          currentStatus: "Open",
          remarks: "",
        },
        active: true,
        relatedNews: [],
        additionalResources: [],
        mediaLink: [
          {
            name: "OpIndia",
            link: "https://www.opindia.com/2023/05/assam-rubel-rehman-imran-mamunur-anowar-abduct-assault-gang-rape-13-year-old-moving-van",
          },
          {
            name: "India Today",
            link: "https://www.indiatodayne.in/assam/story/assam-13-year-old-girl-gang-raped-in-moving-car-in-kokrajhar-560056-2023-05-18",
          },
        ],
        isCrime: true,
        topNews: false,
        title:
          "13-year-old abducted, assaulted and gang raped in a moving van, Rubel Rehman, Imran, Mamunur, Anowar arrested",
        description:
          "A 13-year-old girl was gang-raped by four youths inside a moving car in the Kokrajhar district of Assam. The four accused have been identified as Rubel Rehman, Imran Hussain, Mamunur Rehman and Anowar Hussain all from Dhubri district. The Police have booked and arrested the accused under sections 363, 376(d), 328 of the Indian Penal Code and also under section 6 of the POCSO Act",
        logo: "https://i0.wp.com/www.opindia.com/wp-content/uploads/2023/05/721.png",
        media: [
          {
            _id: "646dd05203393e2a347e5f4a",
            url: "https://akm-img-a-in.tosshub.com/lingo/itne/images/story/202305/gang_rape-sixteen_nine.jpg",
          },
        ],
        stateId: "615ee13f72fd09f23833ca90",
        districtId: "615eeeb0e688dcbb54d4c959",
        victimSearch: "Minor",
        state: "Assam",
        district: "Kokrajhar",
        createdAt: "2023-05-24T08:52:34.687Z",
        updatedAt: "2023-05-24T08:52:34.687Z",
      },
      {
        _id: "646db79303393e2a347e5f3d",
        keyInformation: {
          victimName: ["Mamta"],
          perpetratorName: ["मोहम्मद हसन"],
          incidentDate: "2022-10-25T00:00:00.000Z",
          casualFactors: "Religious Indentity",
          peopleInjured: 1,
          natureOfViolance: "Physical Assault",
          state: "Uttar Pradesh",
          district: "Bareilly",
          partyInPower: "BJP",
        },
        otherInformation: {
          charedFiled: true,
          sourceOfInformation: "News18",
          identityOfPerpetrator: "Muslim",
          numberOfVictim: 1,
          locationOfIncident: "Aligarh",
          perpetratorOrganization: "",
          investigationAgency: "ADGC",
          roleOfPolice: "Investigation",
          judementReference: "",
          identityOfVictim: "Hindu",
          defailsOfFIR: "",
          crossFirFiled: 1,
          victimLawyer: "",
          currentStatus: "Open",
          remarks: "",
        },
        active: true,
        relatedNews: ["646db79303393e2a347e5f3d"],
        additionalResources: [],
        mediaLink: [
          {
            name: "News18",
            link: "https://hindi.news18.com/news/uttar-pradesh/aligarh-adopted-13-year-minor-hindu-daughter-raped-by-muslim-father-20-years-sentenced-6217117.html",
          },
        ],
        isCrime: true,
        topNews: false,
        title:
          "गोद ली हुई नाबालिग हिंदू बेटी के साथ मुस्लिम पिता ने किया रेप, मिली 20 साल की सजा",
        description:
          "“अलीगढ़. अलीगढ़ के थाना क्वार्सी इलाके के मंजूरगढ़ी में 13 वर्षीय नाबालिग हिंदू बेटी के साथ दुष्कर्म के दोषी कथित पिता को 20 वर्ष कैद की सजा सुनाई गई है. यह फैसला एडीजे विशेष पॉक्सो सुरेंद्र मोहन राय की अदालत से सुनाया गया है. बता दें कि बिन मां-बाप की बेटी इस घटना के बाद से नारी निकेतन में रह रही है.अभियोजन अधिवक्ता एडीजीसी महेश सिंह के बताए अनुसार, घटना 25 अक्टूबर 2020 की है. इलाके में ही रहने वाले एक सामाजिक व्यक्ति ने क्वार्सी में मुकदमा दर्ज कराते हुए बताया कि आरोपी 50 वर्षीय पप्पू और मोहम्मद हसन निवासी बिस्मिल्लाह कॉलोनी मंजूरगढ़ी में एक बिन मां-बाप की करीब 13 वर्षीय लड़की को गोद लिया था. घटना वाले दिन आरोपी की पुत्रवधू ने उसे बच्ची संग गंदी हरकत करते देख लिया और शोर मचा दिया.",
        logo: "https://www.google.com/url?sa=i&url=https%3A%2F%2Fcrimealert24x7.com%2F&psig=AOvVaw0eBNZUNbgM9VfXAbR7-i4e&ust=1684558390255000&source=images&cd=vfe&ved=0CBEQjRxqFwoTCIiG_ZXLgP8CFQAAAAAdAAAAABAE",
        media: [
          {
            _id: "646db79303393e2a347e5f3e",
            url: "https://www.google.com/url?sa=i&url=https%3A%2F%2Fcrimealert24x7.com%2F&psig=AOvVaw0eBNZUNbgM9VfXAbR7-i4e&ust=1684558390255000&source=images&cd=vfe&ved=0CBEQjRxqFwoTCIiG_ZXLgP8CFQAAAAAdAAAAABAE",
          },
        ],
        stateId: "615ee13f72fd09f23833ca89",
        districtId: "615eeeb0e688dcbb54d4ca18",
        victimSearch: "Mamta",
        state: "Uttar Pradesh",
        district: "Aligarh",
        createdAt: "2023-05-24T07:06:59.906Z",
        updatedAt: "2023-05-24T07:06:59.906Z",
      },
      {
        _id: "646db79303393e2a347e5f3d",
        keyInformation: {
          victimName: ["Mamta"],
          perpetratorName: ["मोहम्मद हसन"],
          incidentDate: "2022-10-25T00:00:00.000Z",
          casualFactors: "Religious Indentity",
          peopleInjured: 1,
          natureOfViolance: "Physical Assault",
          state: "Uttar Pradesh",
          district: "Bareilly",
          partyInPower: "BJP",
        },
        otherInformation: {
          charedFiled: true,
          sourceOfInformation: "News18",
          identityOfPerpetrator: "Muslim",
          numberOfVictim: 1,
          locationOfIncident: "Aligarh",
          perpetratorOrganization: "",
          investigationAgency: "ADGC",
          roleOfPolice: "Investigation",
          judementReference: "",
          identityOfVictim: "Hindu",
          defailsOfFIR: "",
          crossFirFiled: 1,
          victimLawyer: "",
          currentStatus: "Open",
          remarks: "",
        },
        active: true,
        relatedNews: ["646db79303393e2a347e5f3d"],
        additionalResources: [],
        mediaLink: [
          {
            name: "News18",
            link: "https://hindi.news18.com/news/uttar-pradesh/aligarh-adopted-13-year-minor-hindu-daughter-raped-by-muslim-father-20-years-sentenced-6217117.html",
          },
        ],
        isCrime: true,
        topNews: false,
        title:
          "गोद ली हुई नाबालिग हिंदू बेटी के साथ मुस्लिम पिता ने किया रेप, मिली 20 साल की सजा",
        description:
          "“अलीगढ़. अलीगढ़ के थाना क्वार्सी इलाके के मंजूरगढ़ी में 13 वर्षीय नाबालिग हिंदू बेटी के साथ दुष्कर्म के दोषी कथित पिता को 20 वर्ष कैद की सजा सुनाई गई है. यह फैसला एडीजे विशेष पॉक्सो सुरेंद्र मोहन राय की अदालत से सुनाया गया है. बता दें कि बिन मां-बाप की बेटी इस घटना के बाद से नारी निकेतन में रह रही है.अभियोजन अधिवक्ता एडीजीसी महेश सिंह के बताए अनुसार, घटना 25 अक्टूबर 2020 की है. इलाके में ही रहने वाले एक सामाजिक व्यक्ति ने क्वार्सी में मुकदमा दर्ज कराते हुए बताया कि आरोपी 50 वर्षीय पप्पू और मोहम्मद हसन निवासी बिस्मिल्लाह कॉलोनी मंजूरगढ़ी में एक बिन मां-बाप की करीब 13 वर्षीय लड़की को गोद लिया था. घटना वाले दिन आरोपी की पुत्रवधू ने उसे बच्ची संग गंदी हरकत करते देख लिया और शोर मचा दिया.",
        logo: "https://www.google.com/url?sa=i&url=https%3A%2F%2Fcrimealert24x7.com%2F&psig=AOvVaw0eBNZUNbgM9VfXAbR7-i4e&ust=1684558390255000&source=images&cd=vfe&ved=0CBEQjRxqFwoTCIiG_ZXLgP8CFQAAAAAdAAAAABAE",
        media: [
          {
            _id: "646db79303393e2a347e5f3e",
            url: "https://www.google.com/url?sa=i&url=https%3A%2F%2Fcrimealert24x7.com%2F&psig=AOvVaw0eBNZUNbgM9VfXAbR7-i4e&ust=1684558390255000&source=images&cd=vfe&ved=0CBEQjRxqFwoTCIiG_ZXLgP8CFQAAAAAdAAAAABAE",
          },
        ],
        stateId: "615ee13f72fd09f23833ca89",
        districtId: "615eeeb0e688dcbb54d4ca18",
        victimSearch: "Mamta",
        state: "Uttar Pradesh",
        district: "Aligarh",
        createdAt: "2023-05-24T07:06:59.906Z",
        updatedAt: "2023-05-24T07:06:59.906Z",
      },
      {
        _id: "646db79303393e2a347e5f3d",
        keyInformation: {
          victimName: ["Mamta"],
          perpetratorName: ["मोहम्मद हसन"],
          incidentDate: "2022-10-25T00:00:00.000Z",
          casualFactors: "Religious Indentity",
          peopleInjured: 1,
          natureOfViolance: "Physical Assault",
          state: "Uttar Pradesh",
          district: "Bareilly",
          partyInPower: "BJP",
        },
        otherInformation: {
          charedFiled: true,
          sourceOfInformation: "News18",
          identityOfPerpetrator: "Muslim",
          numberOfVictim: 1,
          locationOfIncident: "Aligarh",
          perpetratorOrganization: "",
          investigationAgency: "ADGC",
          roleOfPolice: "Investigation",
          judementReference: "",
          identityOfVictim: "Hindu",
          defailsOfFIR: "",
          crossFirFiled: 1,
          victimLawyer: "",
          currentStatus: "Open",
          remarks: "",
        },
        active: true,
        relatedNews: ["646db79303393e2a347e5f3d"],
        additionalResources: [],
        mediaLink: [
          {
            name: "News18",
            link: "https://hindi.news18.com/news/uttar-pradesh/aligarh-adopted-13-year-minor-hindu-daughter-raped-by-muslim-father-20-years-sentenced-6217117.html",
          },
        ],
        isCrime: true,
        topNews: false,
        title:
          "गोद ली हुई नाबालिग हिंदू बेटी के साथ मुस्लिम पिता ने किया रेप, मिली 20 साल की सजा",
        description:
          "“अलीगढ़. अलीगढ़ के थाना क्वार्सी इलाके के मंजूरगढ़ी में 13 वर्षीय नाबालिग हिंदू बेटी के साथ दुष्कर्म के दोषी कथित पिता को 20 वर्ष कैद की सजा सुनाई गई है. यह फैसला एडीजे विशेष पॉक्सो सुरेंद्र मोहन राय की अदालत से सुनाया गया है. बता दें कि बिन मां-बाप की बेटी इस घटना के बाद से नारी निकेतन में रह रही है.अभियोजन अधिवक्ता एडीजीसी महेश सिंह के बताए अनुसार, घटना 25 अक्टूबर 2020 की है. इलाके में ही रहने वाले एक सामाजिक व्यक्ति ने क्वार्सी में मुकदमा दर्ज कराते हुए बताया कि आरोपी 50 वर्षीय पप्पू और मोहम्मद हसन निवासी बिस्मिल्लाह कॉलोनी मंजूरगढ़ी में एक बिन मां-बाप की करीब 13 वर्षीय लड़की को गोद लिया था. घटना वाले दिन आरोपी की पुत्रवधू ने उसे बच्ची संग गंदी हरकत करते देख लिया और शोर मचा दिया.",
        logo: "https://www.google.com/url?sa=i&url=https%3A%2F%2Fcrimealert24x7.com%2F&psig=AOvVaw0eBNZUNbgM9VfXAbR7-i4e&ust=1684558390255000&source=images&cd=vfe&ved=0CBEQjRxqFwoTCIiG_ZXLgP8CFQAAAAAdAAAAABAE",
        media: [
          {
            _id: "646db79303393e2a347e5f3e",
            url: "https://www.google.com/url?sa=i&url=https%3A%2F%2Fcrimealert24x7.com%2F&psig=AOvVaw0eBNZUNbgM9VfXAbR7-i4e&ust=1684558390255000&source=images&cd=vfe&ved=0CBEQjRxqFwoTCIiG_ZXLgP8CFQAAAAAdAAAAABAE",
          },
        ],
        stateId: "615ee13f72fd09f23833ca89",
        districtId: "615eeeb0e688dcbb54d4ca18",
        victimSearch: "Mamta",
        state: "Uttar Pradesh",
        district: "Aligarh",
        createdAt: "2023-05-24T07:06:59.906Z",
        updatedAt: "2023-05-24T07:06:59.906Z",
      },
    ],
  },
};
export const getNewsData = [
  {
    result: {
      _id: "647ae94117c21b2e146366fc",
      active: true,
      otherInformation: {
        charedFiled: true,
        sourceOfInformation: "Hindu Post",
        numberOfVictim: 1,
        locationOfIncident: "Bareilly",
        perpetratorOrganization: "",
        investigationAgency: "ADGC",
        roleOfPolice: "Investigation",
        judementReference: "",
        identityOfVictim: "Hindu",
        defailsOfFIR: "",
        crossFirFiled: 1,
        victimLawyer: "",
        currentStatus: "Open",
        remarks: "",
      },
      relatedNews: [],
      additionalResources: [],
      mediaLink: [
        {
          name: "Hindu Post",
          link: "https://hindupost.in/crime/uttar-pradesh-muslim-doctor-who-murdered-his-hindu-second-wife-for-not-converting-to-islam-after-marrying-her-pretending-to-be-hindu-gets-life-in-jail/",
        },
      ],
      isCrime: true,
      title:
        "item 2Uttar Pradesh: Muslim doctor who murdered his Hindu second wife for not converting to Islam after marrying her pretending to be Hindu, gets life in jail",
      description:
        "“In a case of love jihad from Uttar Pradesh, a court in Bareilly sentenced a doctor, Iqbal Ahmad to life imprisonment for killing his second wife, Nisha Devi, a resident of Saharanpur with the assistance of his two aides, Muhammad Yaseen and Misaryar Khan in 2021 on the basis of the testimony of his nine-year-old daughter",
      logo: "https://www.google.com/url?sa=i&url=https%3A%2F%2Fwww.indiatvnews.com%2Fnews%2Findia%2Fcollege-student-found-half-burnt-uttar-pradesh-shahjahanpur-686726&psig=AOvVaw2WsOrjjRFewigcbo6Ume-R&ust=1683868326053000&source=images&cd=vfe&ved=0CBEQjRxqFwoTCKCStr3A7P4CFQAAAAAdAAAAABAE",
      media: [
        {
          _id: "645c7b55ff28886bff7b0b8a",
          url: "https://www.google.com/url?sa=i&url=https%3A%2F%2Fwww.lokmat.com%2Fcrime%2Fcrime-news-atrocity-case-filed-against-assistant-police-inspector-a301%2F&psig=AOvVaw2WsOrjjRFewigcbo6Ume-R&ust=1683868326053000&source=images&cd=vfe&ved=0CBEQjRxqFwoTCKCStr3A7P4CFQAAAAAdAAAAABAJ",
          type: "image",
        },
      ],
      keyInformation: {
        incidentDate: "2023-05-11T00:00:00.000Z",
        casualFactors: "Religious Indentity",
        victimName: "Nisha Devi",
        peopleInjured: 1,
        natureOfVoilance: "Physical Assault",
        perpetratorName: "Muhammad Yaseen",
        state: "Uttar Pradesh",
        district: "Bareilly",
        partyInPower: "BJP",
      },
      createdAt: "2023-05-11T05:21:25.642Z",
      updatedAt: "2023-05-11T05:21:25.642Z",
      states: ["615ee13f72fd09f23833ca89"],
      districts: ["615eeeb0e688dcbb54d4c9e4"],
    },
    relatesNews: [],
  },
  {
    result: {
      _id: "645c7b55ff28886bff7b0b89",
      active: true,
      otherInformation: {
        charedFiled: true,
        sourceOfInformation: "Hindu Post",
        numberOfVictim: 1,
        locationOfIncident: "Bareilly",
        perpetratorOrganization: "",
        investigationAgency: "ADGC",
        roleOfPolice: "Investigation",
        judementReference: "",
        identityOfVictim: "Hindu",
        defailsOfFIR: "",
        crossFirFiled: 1,
        victimLawyer: "",
        currentStatus: "Open",
        remarks: "",
      },
      relatedNews: [],
      additionalResources: [],
      mediaLink: [
        {
          name: "Hindu Post",
          link: "https://hindupost.in/crime/uttar-pradesh-muslim-doctor-who-murdered-his-hindu-second-wife-for-not-converting-to-islam-after-marrying-her-pretending-to-be-hindu-gets-life-in-jail/",
        },
      ],
      isCrime: true,
      title:
        "3Uttar Pradesh: Muslim doctor who murdered his Hindu second wife for not converting to Islam after marrying her pretending to be Hindu, gets life in jail",
      description:
        "“In a case of love jihad from Uttar Pradesh, a court in Bareilly sentenced a doctor, Iqbal Ahmad to life imprisonment for killing his second wife, Nisha Devi, a resident of Saharanpur with the assistance of his two aides, Muhammad Yaseen and Misaryar Khan in 2021 on the basis of the testimony of his nine-year-old daughter",
      logo: "https://www.google.com/url?sa=i&url=https%3A%2F%2Fwww.indiatvnews.com%2Fnews%2Findia%2Fcollege-student-found-half-burnt-uttar-pradesh-shahjahanpur-686726&psig=AOvVaw2WsOrjjRFewigcbo6Ume-R&ust=1683868326053000&source=images&cd=vfe&ved=0CBEQjRxqFwoTCKCStr3A7P4CFQAAAAAdAAAAABAE",
      media: [
        {
          _id: "645c7b55ff28886bff7b0b8a",
          url: "https://www.google.com/url?sa=i&url=https%3A%2F%2Fwww.lokmat.com%2Fcrime%2Fcrime-news-atrocity-case-filed-against-assistant-police-inspector-a301%2F&psig=AOvVaw2WsOrjjRFewigcbo6Ume-R&ust=1683868326053000&source=images&cd=vfe&ved=0CBEQjRxqFwoTCKCStr3A7P4CFQAAAAAdAAAAABAJ",
          type: "image",
        },
      ],
      keyInformation: {
        incidentDate: "2023-05-11T00:00:00.000Z",
        casualFactors: "Religious Indentity",
        victimName: "Nisha Devi",
        peopleInjured: 1,
        natureOfVoilance: "Physical Assault",
        perpetratorName: "Muhammad Yaseen",
        state: "Uttar Pradesh",
        district: "Bareilly",
        partyInPower: "BJP",
      },
      createdAt: "2023-05-11T05:21:25.642Z",
      updatedAt: "2023-05-11T05:21:25.642Z",
      states: ["615ee13f72fd09f23833ca89"],
      districts: ["615eeeb0e688dcbb54d4c9e4"],
    },
    relatesNews: [],
  },
  {
    result: {
      _id: "645c7b55ff28886bff7b0b89",
      active: true,
      otherInformation: {
        charedFiled: true,
        sourceOfInformation: "Hindu Post",
        numberOfVictim: 1,
        locationOfIncident: "Bareilly",
        perpetratorOrganization: "",
        investigationAgency: "ADGC",
        roleOfPolice: "Investigation",
        judementReference: "",
        identityOfVictim: "Hindu",
        defailsOfFIR: "",
        crossFirFiled: 1,
        victimLawyer: "",
        currentStatus: "Open",
        remarks: "",
      },
      relatedNews: [],
      additionalResources: [],
      mediaLink: [
        {
          name: "Hindu Post",
          link: "https://hindupost.in/crime/uttar-pradesh-muslim-doctor-who-murdered-his-hindu-second-wife-for-not-converting-to-islam-after-marrying-her-pretending-to-be-hindu-gets-life-in-jail/",
        },
      ],
      isCrime: true,
      title:
        "4Uttar Pradesh: Muslim doctor who murdered his Hindu second wife for not converting to Islam after marrying her pretending to be Hindu, gets life in jail",
      description:
        "“In a case of love jihad from Uttar Pradesh, a court in Bareilly sentenced a doctor, Iqbal Ahmad to life imprisonment for killing his second wife, Nisha Devi, a resident of Saharanpur with the assistance of his two aides, Muhammad Yaseen and Misaryar Khan in 2021 on the basis of the testimony of his nine-year-old daughter",
      logo: "https://www.google.com/url?sa=i&url=https%3A%2F%2Fwww.indiatvnews.com%2Fnews%2Findia%2Fcollege-student-found-half-burnt-uttar-pradesh-shahjahanpur-686726&psig=AOvVaw2WsOrjjRFewigcbo6Ume-R&ust=1683868326053000&source=images&cd=vfe&ved=0CBEQjRxqFwoTCKCStr3A7P4CFQAAAAAdAAAAABAE",
      media: [
        {
          _id: "645c7b55ff28886bff7b0b8a",
          url: "https://www.google.com/url?sa=i&url=https%3A%2F%2Fwww.lokmat.com%2Fcrime%2Fcrime-news-atrocity-case-filed-against-assistant-police-inspector-a301%2F&psig=AOvVaw2WsOrjjRFewigcbo6Ume-R&ust=1683868326053000&source=images&cd=vfe&ved=0CBEQjRxqFwoTCKCStr3A7P4CFQAAAAAdAAAAABAJ",
          type: "image",
        },
      ],
      keyInformation: {
        incidentDate: "2023-05-11T00:00:00.000Z",
        casualFactors: "Religious Indentity",
        victimName: "Nisha Devi",
        peopleInjured: 1,
        natureOfVoilance: "Physical Assault",
        perpetratorName: "Muhammad Yaseen",
        state: "Uttar Pradesh",
        district: "Bareilly",
        partyInPower: "BJP",
      },
      createdAt: "2023-05-11T05:21:25.642Z",
      updatedAt: "2023-05-11T05:21:25.642Z",
      states: ["615ee13f72fd09f23833ca89"],
      districts: ["615eeeb0e688dcbb54d4c9e4"],
    },
    relatesNews: [],
  },
  {
    result: {
      _id: "645c7b55ff28886bff7b0b89",
      active: true,
      otherInformation: {
        charedFiled: true,
        sourceOfInformation: "Hindu Post",
        numberOfVictim: 1,
        locationOfIncident: "Bareilly",
        perpetratorOrganization: "",
        investigationAgency: "ADGC",
        roleOfPolice: "Investigation",
        judementReference: "",
        identityOfVictim: "Hindu",
        defailsOfFIR: "",
        crossFirFiled: 1,
        victimLawyer: "",
        currentStatus: "Open",
        remarks: "",
      },
      relatedNews: [],
      additionalResources: [],
      mediaLink: [
        {
          name: "Hindu Post",
          link: "https://hindupost.in/crime/uttar-pradesh-muslim-doctor-who-murdered-his-hindu-second-wife-for-not-converting-to-islam-after-marrying-her-pretending-to-be-hindu-gets-life-in-jail/",
        },
      ],
      isCrime: true,
      title:
        "Uttar Pradesh: Muslim doctor who murdered his Hindu second wife for not converting to Islam after marrying her pretending to be Hindu, gets life in jail",
      description:
        "“In a case of love jihad from Uttar Pradesh, a court in Bareilly sentenced a doctor, Iqbal Ahmad to life imprisonment for killing his second wife, Nisha Devi, a resident of Saharanpur with the assistance of his two aides, Muhammad Yaseen and Misaryar Khan in 2021 on the basis of the testimony of his nine-year-old daughter",
      logo: "https://www.google.com/url?sa=i&url=https%3A%2F%2Fwww.indiatvnews.com%2Fnews%2Findia%2Fcollege-student-found-half-burnt-uttar-pradesh-shahjahanpur-686726&psig=AOvVaw2WsOrjjRFewigcbo6Ume-R&ust=1683868326053000&source=images&cd=vfe&ved=0CBEQjRxqFwoTCKCStr3A7P4CFQAAAAAdAAAAABAE",
      media: [
        {
          _id: "645c7b55ff28886bff7b0b8a",
          url: "https://www.google.com/url?sa=i&url=https%3A%2F%2Fwww.lokmat.com%2Fcrime%2Fcrime-news-atrocity-case-filed-against-assistant-police-inspector-a301%2F&psig=AOvVaw2WsOrjjRFewigcbo6Ume-R&ust=1683868326053000&source=images&cd=vfe&ved=0CBEQjRxqFwoTCKCStr3A7P4CFQAAAAAdAAAAABAJ",
          type: "image",
        },
      ],
      keyInformation: {
        incidentDate: "2023-05-11T00:00:00.000Z",
        casualFactors: "Religious Indentity",
        victimName: "Nisha Devi",
        peopleInjured: 1,
        natureOfVoilance: "Physical Assault",
        perpetratorName: "Muhammad Yaseen",
        state: "Uttar Pradesh",
        district: "Bareilly",
        partyInPower: "BJP",
      },
      createdAt: "2023-05-11T05:21:25.642Z",
      updatedAt: "2023-05-11T05:21:25.642Z",
      states: ["615ee13f72fd09f23833ca89"],
      districts: ["615eeeb0e688dcbb54d4c9e4"],
    },
    relatesNews: [],
  },
  {
    result: {
      _id: "645c7b55ff28886bff7b0b89",
      active: true,
      otherInformation: {
        charedFiled: true,
        sourceOfInformation: "Hindu Post",
        numberOfVictim: 1,
        locationOfIncident: "Bareilly",
        perpetratorOrganization: "",
        investigationAgency: "ADGC",
        roleOfPolice: "Investigation",
        judementReference: "",
        identityOfVictim: "Hindu",
        defailsOfFIR: "",
        crossFirFiled: 1,
        victimLawyer: "",
        currentStatus: "Open",
        remarks: "",
      },
      relatedNews: [],
      additionalResources: [],
      mediaLink: [
        {
          name: "Hindu Post",
          link: "https://hindupost.in/crime/uttar-pradesh-muslim-doctor-who-murdered-his-hindu-second-wife-for-not-converting-to-islam-after-marrying-her-pretending-to-be-hindu-gets-life-in-jail/",
        },
      ],
      isCrime: true,
      title:
        "Uttar Pradesh: Muslim doctor who murdered his Hindu second wife for not converting to Islam after marrying her pretending to be Hindu, gets life in jail",
      description:
        "“In a case of love jihad from Uttar Pradesh, a court in Bareilly sentenced a doctor, Iqbal Ahmad to life imprisonment for killing his second wife, Nisha Devi, a resident of Saharanpur with the assistance of his two aides, Muhammad Yaseen and Misaryar Khan in 2021 on the basis of the testimony of his nine-year-old daughter",
      logo: "https://www.google.com/url?sa=i&url=https%3A%2F%2Fwww.indiatvnews.com%2Fnews%2Findia%2Fcollege-student-found-half-burnt-uttar-pradesh-shahjahanpur-686726&psig=AOvVaw2WsOrjjRFewigcbo6Ume-R&ust=1683868326053000&source=images&cd=vfe&ved=0CBEQjRxqFwoTCKCStr3A7P4CFQAAAAAdAAAAABAE",
      media: [
        {
          _id: "645c7b55ff28886bff7b0b8a",
          url: "https://www.google.com/url?sa=i&url=https%3A%2F%2Fwww.lokmat.com%2Fcrime%2Fcrime-news-atrocity-case-filed-against-assistant-police-inspector-a301%2F&psig=AOvVaw2WsOrjjRFewigcbo6Ume-R&ust=1683868326053000&source=images&cd=vfe&ved=0CBEQjRxqFwoTCKCStr3A7P4CFQAAAAAdAAAAABAJ",
          type: "image",
        },
      ],
      keyInformation: {
        incidentDate: "2023-05-11T00:00:00.000Z",
        casualFactors: "Religious Indentity",
        victimName: "Nisha Devi",
        peopleInjured: 1,
        natureOfVoilance: "Physical Assault",
        perpetratorName: "Muhammad Yaseen",
        state: "Uttar Pradesh",
        district: "Bareilly",
        partyInPower: "BJP",
      },
      createdAt: "2023-05-11T05:21:25.642Z",
      updatedAt: "2023-05-11T05:21:25.642Z",
      states: ["615ee13f72fd09f23833ca89"],
      districts: ["615eeeb0e688dcbb54d4c9e4"],
    },
    relatesNews: [],
  },
  {
    result: {
      _id: "645c7b55ff28886bff7b0b89",
      active: true,
      otherInformation: {
        charedFiled: true,
        sourceOfInformation: "Hindu Post",
        numberOfVictim: 1,
        locationOfIncident: "Bareilly",
        perpetratorOrganization: "",
        investigationAgency: "ADGC",
        roleOfPolice: "Investigation",
        judementReference: "",
        identityOfVictim: "Hindu",
        defailsOfFIR: "",
        crossFirFiled: 1,
        victimLawyer: "",
        currentStatus: "Open",
        remarks: "",
      },
      relatedNews: [],
      additionalResources: [],
      mediaLink: [
        {
          name: "Hindu Post",
          link: "https://hindupost.in/crime/uttar-pradesh-muslim-doctor-who-murdered-his-hindu-second-wife-for-not-converting-to-islam-after-marrying-her-pretending-to-be-hindu-gets-life-in-jail/",
        },
      ],
      isCrime: true,
      title:
        "Uttar Pradesh: Muslim doctor who murdered his Hindu second wife for not converting to Islam after marrying her pretending to be Hindu, gets life in jail",
      description:
        "“In a case of love jihad from Uttar Pradesh, a court in Bareilly sentenced a doctor, Iqbal Ahmad to life imprisonment for killing his second wife, Nisha Devi, a resident of Saharanpur with the assistance of his two aides, Muhammad Yaseen and Misaryar Khan in 2021 on the basis of the testimony of his nine-year-old daughter",
      logo: "https://www.google.com/url?sa=i&url=https%3A%2F%2Fwww.indiatvnews.com%2Fnews%2Findia%2Fcollege-student-found-half-burnt-uttar-pradesh-shahjahanpur-686726&psig=AOvVaw2WsOrjjRFewigcbo6Ume-R&ust=1683868326053000&source=images&cd=vfe&ved=0CBEQjRxqFwoTCKCStr3A7P4CFQAAAAAdAAAAABAE",
      media: [
        {
          _id: "645c7b55ff28886bff7b0b8a",
          url: "https://www.google.com/url?sa=i&url=https%3A%2F%2Fwww.lokmat.com%2Fcrime%2Fcrime-news-atrocity-case-filed-against-assistant-police-inspector-a301%2F&psig=AOvVaw2WsOrjjRFewigcbo6Ume-R&ust=1683868326053000&source=images&cd=vfe&ved=0CBEQjRxqFwoTCKCStr3A7P4CFQAAAAAdAAAAABAJ",
          type: "image",
        },
      ],
      keyInformation: {
        incidentDate: "2023-05-11T00:00:00.000Z",
        casualFactors: "Religious Indentity",
        victimName: "Nisha Devi",
        peopleInjured: 1,
        natureOfVoilance: "Physical Assault",
        perpetratorName: "Muhammad Yaseen",
        state: "Uttar Pradesh",
        district: "Bareilly",
        partyInPower: "BJP",
      },
      createdAt: "2023-05-11T05:21:25.642Z",
      updatedAt: "2023-05-11T05:21:25.642Z",
      states: ["615ee13f72fd09f23833ca89"],
      districts: ["615eeeb0e688dcbb54d4c9e4"],
    },
    relatesNews: [],
  },
  {
    result: {
      _id: "645c7b55ff28886bff7b0b89",
      active: true,
      otherInformation: {
        charedFiled: true,
        sourceOfInformation: "Hindu Post",
        numberOfVictim: 1,
        locationOfIncident: "Bareilly",
        perpetratorOrganization: "",
        investigationAgency: "ADGC",
        roleOfPolice: "Investigation",
        judementReference: "",
        identityOfVictim: "Hindu",
        defailsOfFIR: "",
        crossFirFiled: 1,
        victimLawyer: "",
        currentStatus: "Open",
        remarks: "",
      },
      relatedNews: [],
      additionalResources: [],
      mediaLink: [
        {
          name: "Hindu Post",
          link: "https://hindupost.in/crime/uttar-pradesh-muslim-doctor-who-murdered-his-hindu-second-wife-for-not-converting-to-islam-after-marrying-her-pretending-to-be-hindu-gets-life-in-jail/",
        },
      ],
      isCrime: true,
      title:
        "Uttar Pradesh: Muslim doctor who murdered his Hindu second wife for not converting to Islam after marrying her pretending to be Hindu, gets life in jail",
      description:
        "“In a case of love jihad from Uttar Pradesh, a court in Bareilly sentenced a doctor, Iqbal Ahmad to life imprisonment for killing his second wife, Nisha Devi, a resident of Saharanpur with the assistance of his two aides, Muhammad Yaseen and Misaryar Khan in 2021 on the basis of the testimony of his nine-year-old daughter",
      logo: "https://www.google.com/url?sa=i&url=https%3A%2F%2Fwww.indiatvnews.com%2Fnews%2Findia%2Fcollege-student-found-half-burnt-uttar-pradesh-shahjahanpur-686726&psig=AOvVaw2WsOrjjRFewigcbo6Ume-R&ust=1683868326053000&source=images&cd=vfe&ved=0CBEQjRxqFwoTCKCStr3A7P4CFQAAAAAdAAAAABAE",
      media: [
        {
          _id: "645c7b55ff28886bff7b0b8a",
          url: "https://www.google.com/url?sa=i&url=https%3A%2F%2Fwww.lokmat.com%2Fcrime%2Fcrime-news-atrocity-case-filed-against-assistant-police-inspector-a301%2F&psig=AOvVaw2WsOrjjRFewigcbo6Ume-R&ust=1683868326053000&source=images&cd=vfe&ved=0CBEQjRxqFwoTCKCStr3A7P4CFQAAAAAdAAAAABAJ",
          type: "image",
        },
      ],
      keyInformation: {
        incidentDate: "2023-05-11T00:00:00.000Z",
        casualFactors: "Religious Indentity",
        victimName: "Nisha Devi",
        peopleInjured: 1,
        natureOfVoilance: "Physical Assault",
        perpetratorName: "Muhammad Yaseen",
        state: "Uttar Pradesh",
        district: "Bareilly",
        partyInPower: "BJP",
      },
      createdAt: "2023-05-11T05:21:25.642Z",
      updatedAt: "2023-05-11T05:21:25.642Z",
      states: ["615ee13f72fd09f23833ca89"],
      districts: ["615eeeb0e688dcbb54d4c9e4"],
    },
    relatesNews: [],
  },
  {
    result: {
      _id: "645c7b55ff28886bff7b0b89",
      active: true,
      otherInformation: {
        charedFiled: true,
        sourceOfInformation: "Hindu Post",
        numberOfVictim: 1,
        locationOfIncident: "Bareilly",
        perpetratorOrganization: "",
        investigationAgency: "ADGC",
        roleOfPolice: "Investigation",
        judementReference: "",
        identityOfVictim: "Hindu",
        defailsOfFIR: "",
        crossFirFiled: 1,
        victimLawyer: "",
        currentStatus: "Open",
        remarks: "",
      },
      relatedNews: [],
      additionalResources: [],
      mediaLink: [
        {
          name: "Hindu Post",
          link: "https://hindupost.in/crime/uttar-pradesh-muslim-doctor-who-murdered-his-hindu-second-wife-for-not-converting-to-islam-after-marrying-her-pretending-to-be-hindu-gets-life-in-jail/",
        },
      ],
      isCrime: true,
      title:
        "9Uttar Pradesh: Muslim doctor who murdered his Hindu second wife for not converting to Islam after marrying her pretending to be Hindu, gets life in jail",
      description:
        "“In a case of love jihad from Uttar Pradesh, a court in Bareilly sentenced a doctor, Iqbal Ahmad to life imprisonment for killing his second wife, Nisha Devi, a resident of Saharanpur with the assistance of his two aides, Muhammad Yaseen and Misaryar Khan in 2021 on the basis of the testimony of his nine-year-old daughter",
      logo: "https://www.google.com/url?sa=i&url=https%3A%2F%2Fwww.indiatvnews.com%2Fnews%2Findia%2Fcollege-student-found-half-burnt-uttar-pradesh-shahjahanpur-686726&psig=AOvVaw2WsOrjjRFewigcbo6Ume-R&ust=1683868326053000&source=images&cd=vfe&ved=0CBEQjRxqFwoTCKCStr3A7P4CFQAAAAAdAAAAABAE",
      media: [
        {
          _id: "645c7b55ff28886bff7b0b8a",
          url: "https://www.google.com/url?sa=i&url=https%3A%2F%2Fwww.lokmat.com%2Fcrime%2Fcrime-news-atrocity-case-filed-against-assistant-police-inspector-a301%2F&psig=AOvVaw2WsOrjjRFewigcbo6Ume-R&ust=1683868326053000&source=images&cd=vfe&ved=0CBEQjRxqFwoTCKCStr3A7P4CFQAAAAAdAAAAABAJ",
          type: "image",
        },
      ],
      keyInformation: {
        incidentDate: "2023-05-11T00:00:00.000Z",
        casualFactors: "Religious Indentity",
        victimName: "Nisha Devi",
        peopleInjured: 1,
        natureOfVoilance: "Physical Assault",
        perpetratorName: "Muhammad Yaseen",
        state: "Uttar Pradesh",
        district: "Bareilly",
        partyInPower: "BJP",
      },
      createdAt: "2023-05-11T05:21:25.642Z",
      updatedAt: "2023-05-11T05:21:25.642Z",
      states: ["615ee13f72fd09f23833ca89"],
      districts: ["615eeeb0e688dcbb54d4c9e4"],
    },
    relatesNews: [],
  },
  {
    result: {
      _id: "645c7b55ff28886bff7b0b89",
      active: true,
      otherInformation: {
        charedFiled: true,
        sourceOfInformation: "Hindu Post",
        numberOfVictim: 1,
        locationOfIncident: "Bareilly",
        perpetratorOrganization: "",
        investigationAgency: "ADGC",
        roleOfPolice: "Investigation",
        judementReference: "",
        identityOfVictim: "Hindu",
        defailsOfFIR: "",
        crossFirFiled: 1,
        victimLawyer: "",
        currentStatus: "Open",
        remarks: "",
      },
      relatedNews: [],
      additionalResources: [],
      mediaLink: [
        {
          name: "Hindu Post",
          link: "https://hindupost.in/crime/uttar-pradesh-muslim-doctor-who-murdered-his-hindu-second-wife-for-not-converting-to-islam-after-marrying-her-pretending-to-be-hindu-gets-life-in-jail/",
        },
      ],
      isCrime: true,
      title:
        "10Uttar Pradesh: Muslim doctor who murdered his Hindu second wife for not converting to Islam after marrying her pretending to be Hindu, gets life in jail",
      description:
        "“In a case of love jihad from Uttar Pradesh, a court in Bareilly sentenced a doctor, Iqbal Ahmad to life imprisonment for killing his second wife, Nisha Devi, a resident of Saharanpur with the assistance of his two aides, Muhammad Yaseen and Misaryar Khan in 2021 on the basis of the testimony of his nine-year-old daughter",
      logo: "https://www.google.com/url?sa=i&url=https%3A%2F%2Fwww.indiatvnews.com%2Fnews%2Findia%2Fcollege-student-found-half-burnt-uttar-pradesh-shahjahanpur-686726&psig=AOvVaw2WsOrjjRFewigcbo6Ume-R&ust=1683868326053000&source=images&cd=vfe&ved=0CBEQjRxqFwoTCKCStr3A7P4CFQAAAAAdAAAAABAE",
      media: [
        {
          _id: "645c7b55ff28886bff7b0b8a",
          url: "https://www.google.com/url?sa=i&url=https%3A%2F%2Fwww.lokmat.com%2Fcrime%2Fcrime-news-atrocity-case-filed-against-assistant-police-inspector-a301%2F&psig=AOvVaw2WsOrjjRFewigcbo6Ume-R&ust=1683868326053000&source=images&cd=vfe&ved=0CBEQjRxqFwoTCKCStr3A7P4CFQAAAAAdAAAAABAJ",
          type: "image",
        },
      ],
      keyInformation: {
        incidentDate: "2023-05-11T00:00:00.000Z",
        casualFactors: "Religious Indentity",
        victimName: "Nisha Devi",
        peopleInjured: 1,
        natureOfVoilance: "Physical Assault",
        perpetratorName: "Muhammad Yaseen",
        state: "Uttar Pradesh",
        district: "Bareilly",
        partyInPower: "BJP",
      },
      createdAt: "2023-05-11T05:21:25.642Z",
      updatedAt: "2023-05-11T05:21:25.642Z",
      states: ["615ee13f72fd09f23833ca89"],
      districts: ["615eeeb0e688dcbb54d4c9e4"],
    },
    relatesNews: [],
  },
];

export const getStateList = {
  states: [
    {
      _id: "615ee13f72fd09f23833ca78",
      state: "Andaman and Nicobar Islands",
      stateLocalized: {
        hi: "अंडमान व नोकोबार द्वीप समूह",
      },
    },
    {
      _id: "615ee13f72fd09f23833ca8c",
      state: "Andhra Pradesh",
      stateLocalized: {
        hi: "आंध्र प्रदेश",
      },
    },
    {
      _id: "615ee13f72fd09f23833ca80",
      state: "Arunachal Pradesh",
      stateLocalized: {
        hi: "अरुणाचल प्रदेश",
      },
    },
    {
      _id: "615ee13f72fd09f23833ca90",
      state: "Assam",
      stateLocalized: {
        hi: "असम",
      },
    },
    {
      _id: "615ee13f72fd09f23833ca88",
      state: "Bihar",
      stateLocalized: {
        hi: "बिहार",
      },
    },
    {
      _id: "615ee13f72fd09f23833ca81",
      state: "Chandigarh",
      stateLocalized: {
        hi: "चंडीगढ़",
      },
    },
    {
      _id: "615ee13f72fd09f23833ca87",
      state: "Chhattisgarh",
      stateLocalized: {
        hi: "छत्तीसगढ",
      },
    },
    {
      _id: "615ee13f72fd09f23833ca8e",
      state: "Dadra & Nagar Haveli",
      stateLocalized: {
        hi: "दादरा और नगर हवेली",
      },
    },
    {
      _id: "615ee13f72fd09f23833ca8b",
      state: "Daman & Diu",
      stateLocalized: {
        hi: "दमन और दीव",
      },
    },
    {
      _id: "622877ac11c52c85203d6391",
      state: "Delhi NCR",
      stateLocalized: {
        hi: "दिल्ली एनसीआर",
      },
    },
  ],
};

export const getDistrictList = {
  districts: [
    {
      _id: "615eeeb0e688dcbb54d4c7eb",
      district: "Nicobars",
      districtLocalized: {
        hi: "निकोबार",
      },
    },
    {
      _id: "615eeeb0e688dcbb54d4c7ec",
      district: "North  & Middle Andaman",
      districtLocalized: {
        hi: "उत्तर और मध्य अंडमान   ",
      },
    },
    {
      _id: "615eeeb0e688dcbb54d4c7ed",
      district: "South Andaman",
      districtLocalized: {
        hi: "दक्षिण अंडमान   ",
      },
    },
  ],
};

export const dataBaseColumns = [
  {
    title: "S. No.",
    dataIndex: "serialNumber",
    key: "serialNumber",
    sorter: (a, b) => Number(a.serialNumber) - Number(b.serialNumber),
  },
  {
    title: "Name of the Incident",
    dataIndex: "nameOfTheIncident",
    key: "nameOfTheIncident",
    sorter: (a, b) =>
      a.nameOfTheIncident
        ?.toString()
        ?.localeCompare(b?.nameOfTheIncident?.toString()),
  },
  {
    title: "Date",
    dataIndex: "date",
    key: "date",
    sorter: (a, b) => (moment(a.date) > moment(b.date) ? 1 : -1),
  },
  {
    title: "Place",
    dataIndex: "place",
    key: "place",
    sorter: (a, b) => a?.place?.toString()?.localeCompare(b?.place?.toString()),
  },
  {
    title: "Location",
    dataIndex: "location",
    key: "location",
    sorter: (a, b) =>
      a?.location?.toString()?.localeCompare(b?.location?.toString()),
  },
  {
    title: "State",
    dataIndex: "state",
    key: "state",
    sorter: (a, b) => a?.state?.toString()?.localeCompare(b?.state?.toString()),
  },
  {
    title: "Victim",
    dataIndex: "victim",
    key: "victim",
    sorter: (a, b) =>
      a?.victim?.toString()?.localeCompare(b?.victim?.toString()),
  },
  {
    title: "Number of Victims",
    dataIndex: "numberOfVictims",
    key: "numberOfVictims",
    sorter: (a, b) => Number(a.numberOfVictims) - Number(b.numberOfVictims),
  },
  {
    title: "Causal Factors",
    dataIndex: "casualFactors",
    key: "casualFactors",
    sorter: (a, b) =>
      a?.casualFactors?.toString()?.localeCompare(b?.casualFactors?.toString()),
  },
  {
    title: "Nature of Violence",
    dataIndex: "natureOfViolence",
    key: "natureOfViolence",
    sorter: (a, b) =>
      a?.natureOfViolence
        ?.toString()
        ?.localeCompare(b?.natureOfViolence?.toString()),
  },
  {
    title: "Action",
    dataIndex: "action",
    key: "action",
    // sorter: (a, b) =>
    //   a?.action?.toString()?.localeCompare(b?.action?.toString()),
  },
];
export const graphColorsArray = [
  "#00401A",
  " #0C4925",
  " #195330",
  " #255C3B",
  " #326646",
  " #3E6F52",
  " #4B795D",
  " #578268",
  " #638B73",
  " #70957E",
  " #7C9E89",
  " #89A894",
  " #95B19F",
  " #A1BAAA",
  " #AEC4B5",
  " #BACDC1",
  " #C7D7CC",
  " #D3E0D7",
  " #E0EAE2",
  " #ECF3ED",
];
export const graphColorsRedArray = [
  "#ECFFF4",
  "#E0FBEB",
  "#D3F6E2",
  "#C7F2D8",
  "#BAEECF",
  "#AEE9C6",
  "#A1E5BD",
  "#95E1B4",
  "#89DCAA",
  "#7CD8A1",
  "#70D498",
  "#63D08F",
  "#57CB85",
  "#4BC77C",
  "#3EC373",
  "#32BE6A",
  "#25BA61",
  "#19B657",
  "#0CB14E",
  "#00AD45",
];
export const getRadicalIndividuals = [
  { name: "Lorem Ispum", organisation: "Organisation Name" },
  { name: "Lorem Ispum", organisation: "Organisation Name" },
  { name: "Lorem Ispum", organisation: "Organisation Name" },
  { name: "Lorem Ispum", organisation: "Organisation Name" },
  { name: "Lorem Ispum", organisation: "Organisation Name" },
  { name: "Lorem Ispum", organisation: "Organisation Name" },
  { name: "Lorem Ispum", organisation: "Organisation Name" },
  { name: "Lorem Ispum", organisation: "Organisation Name" },
  { name: "Lorem Ispum", organisation: "Organisation Name" },
  { name: "Lorem Ispum", organisation: "Organisation Name" },
];
export const getRadicalOrganisations = [
  { name: "Organisation Name", organisation: "Founder's Name" },
  { name: "Organisation Name", organisation: "Founder's Name" },
  { name: "Organisation Name", organisation: "Founder's Name" },
  { name: "Organisation Name", organisation: "Founder's Name" },
  { name: "Organisation Name", organisation: "Founder's Name" },
  { name: "Organisation Name", organisation: "Founder's Name" },
  { name: "Organisation Name", organisation: "Founder's Name" },
  { name: "Organisation Name", organisation: "Founder's Name" },
  { name: "Organisation Name", organisation: "Founder's Name" },
  { name: "Organisation Name", organisation: "Founder's Name" },
];
export const getRadicalDetail = {
  result: {
    _id: "645c90b75fbc0cc1a45f4c30",
    active: true,
    isEvent: true,
    stateId: [],
    districtId: [],
    relatedNews: [],
    additionalResources: [],
    mediaLink: [
      {
        name: "Hindu Post",
        link: "https://hindupost.in/crime/uttar-pradesh-muslim-doctor-who-murdered-his-hindu-second-wife-for-not-converting-to-islam-after-marrying-her-pretending-to-be-hindu-gets-life-in-jail/",
      },
    ],
    individuals: [],
    organizations: [],
    title: "Nidhi Devi",
    description:
      "“In a case of love jihad from Uttar Pradesh, a court in Bareilly sentenced a doctor, Iqbal Ahmad to life imprisonment for killing his second wife, Nisha Devi, a resident of Saharanpur with the assistance of his two aides, Muhammad Yaseen and Misaryar Khan in 2021 on the basis of the testimony of his nine-year-old daughter",
    logo: "https://www.google.com/url?sa=i&url=https%3A%2F%2Fwww.indiatvnews.com%2Fnews%2Findia%2Fcollege-student-found-half-burnt-uttar-pradesh-shahjahanpur-686726&psig=AOvVaw2WsOrjjRFewigcbo6Ume-R&ust=1683868326053000&source=images&cd=vfe&ved=0CBEQjRxqFwoTCKCStr3A7P4CFQAAAAAdAAAAABAE",
    media: [
      {
        _id: "645c90b75fbc0cc1a45f4c31",
        url: "https://www.google.com/url?sa=i&url=https%3A%2F%2Fwww.lokmat.com%2Fcrime%2Fcrime-news-atrocity-case-filed-against-assistant-police-inspector-a301%2F&psig=AOvVaw2WsOrjjRFewigcbo6Ume-R&ust=1683868326053000&source=images&cd=vfe&ved=0CBEQjRxqFwoTCKCStr3A7P4CFQAAAAAdAAAAABAJ",
        type: "image",
      },
    ],
    type: "individual",
    keyHighlight: "Danger",
    createdAt: "2023-05-11T06:52:39.503Z",
    updatedAt: "2023-05-11T06:52:39.503Z",
  },
  relatesNews: [],
  individualRadical: [],
  organizationsRadicals: [],
};

export const barChartData = {
  labels: ["Category 1", "Category 2", "Category 3", "Category 4"],
  datasets: [
    {
      // label: "Bar Chart",
      data: [600, 500, 400, 300],
      backgroundColor: ["#FF3131", "#FF5959", "#FF8282", "#FFAFAF"],
      // borderSkipped: "bottom",
      // barThickness: "flex",
      // borderRadius: 12,
      // borderSkipped: {
      //   bottom: false,
      // },
    },
  ],
};

export const splineChartData4 = {
  labels: ["January", "February", "March", "April", "May", "June"],
  datasets: [
    {
      data: [12, 19, 3, 5, 2, 3],
      borderColor: "rgba(108, 117, 125, 0.439216)",
      backgroundColor: "transparent",
      tension: 0.5,
      pointRadius: 0, // Remove data point radius
      pointHoverRadius: 0, // Remove data point hover radius
      // borderWidth: 2,
    },
  ],
};
export const splineChartData1 = {
  labels: ["January", "February", "March", "April", "May", "June"],
  datasets: [
    {
      data: [12, 19, 3, 5, 2, 3],
      borderColor: "rgba(255, 7, 58, 0.439216)",
      backgroundColor: "transparent",
      tension: 0.5,
      pointRadius: 0, // Remove data point radius
      pointHoverRadius: 0, // Remove data point hover radius
      // borderWidth: 2,
    },
  ],
};
export const splineChartData2 = {
  labels: ["January", "February", "March", "April", "May", "June"],
  datasets: [
    {
      data: [12, 19, 3, 5, 2, 3],
      borderColor: "rgba(0, 123, 255, 0.439216)",
      backgroundColor: "transparent",
      tension: 0.5,
      pointRadius: 0, // Remove data point radius
      pointHoverRadius: 0, // Remove data point hover radius
      // borderWidth: 2,
    },
  ],
};
export const splineChartData3 = {
  labels: ["January", "February", "March", "April", "May", "June"],
  datasets: [
    {
      data: [12, 19, 3, 5, 2, 3],
      borderColor: "rgba(40, 167, 69, 0.439216)",
      backgroundColor: "transparent",
      tension: 0.5,
      pointRadius: 0, // Remove data point radius
      pointHoverRadius: 0, // Remove data point hover radius
      // borderWidth: 2,
    },
  ],
};
export const doughnutChartData = {
  labels: ["Red", "Blue", "Yellow", "Green"],
  datasets: [
    {
      // label: "Doughnut Chart",
      data: [30, 50, 40, 10],
      backgroundColor: graphColorsArray,
    },
  ],
};

export const colorsArray = [
  "00401A",
  "0C4925",
  "195330",
  "255C3B",
  "326646",
  "3E6F52",
  "4B795D",
  "578268",
  "638B73",
  "70957E",
  "7C9E89",
  "89A894",
  "95B19F",
  "A1BAAA",
  "AEC4B5",
  "BACDC1",
  "C7D7CC",
  "D3E0D7",
  "E0EAE2",
  "ECF3ED",
];
export const natureOfViolanceArr = [
  "Breaking vehicles",
  "Love Jihad",
  "Communal tension/Violence/Riot",
  "Demolition Attack on property",
  "Denial of access to public spaces/institutions",
  "Discrimination in Education",
  "Discrimination in Employment",
  "Economic boycott",
  "Encounter killing/Attack",
  "Extra Legal Acquisition/appropriation of land",
  "Forced conversion",
  "Harassment",
  "Hate Speech Slander",
  "Housing discrimination",
  "Institutional discrimination",
  "Murder/Lynch",
  "Other",
  "People leaving traditional food habits/Livelihood/Property",
  "Physical assault",
  "Rape",
  "Sexual Harassment",
  "Social boycott",
  "Threat",
  "Unlawful Detention",
  "Vandalism Hooliganism",
  "Verbal Abuse",
];
export const casualFactorsArr = [
  "Access to public spaces institutions",
  "Allegation of religious conversion",
  "Alleged sale / consumption of other Non-Vegetarian diet",
  "Attack against work/ideology/activism of individual or group",
  "Celebration of Festivals",
  "Clothing and appearance",
  "Cow Related Violence",
  "Dispute over Places of Worship",
  "Hurting religious sentiments",
  "Instigate Communal Riots / Clash",
  "Inter-religious marriage / elopement / affair",
  "Land Dispute / Encroachment",
  "Manufactured Rumour",
  "Other",
  "Police encounter",
  "Religious identity",
];
export const victimsReligonArr = [
  "Hindu",
  "Muslim",
  "Sikhs",
  "Christian",
  "Buddhists",
  "Unknown",
  "Police / Investigation Agency",
  "Tribal",
];
