import React from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import News from "../../Pages/News";
import Home from "../../Pages/Home";
import Network from "../../Pages/Network";
import Database from "../../Pages/Database";
import NewsDetail from "../../Pages/News/Detail";
import NetworkDetail from "../../Pages/Network/NetworkDetail";
import Reports from "../../Pages/Reports";
import ReportsDetail from "../../Pages/Reports/ReportDetail";
import SearchPage from "../../Pages/Home/searchPage";
import RadicalList from "../../Pages/Network/RadicalList";
import AboutUs from "../../Pages/AboutUs";
import SupportUs from "../../Pages/SupportUs";
import Contributors from "../../Pages/Contributors";

function AppRoutes() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/related-search" element={<SearchPage />} />
      <Route path="/news" element={<News />} />
      <Route path="/news/detail/:id" element={<NewsDetail />} />
      <Route path="/network" element={<Network />} />
      <Route path="/database" element={<Database />} />
      <Route path="/about-us" element={<AboutUs />} />
      <Route path="/support-us" element={<SupportUs />} />
      <Route path="/contributors" element={<Contributors />} />
      <Route path="/network/radical-individuals" element={<RadicalList />} />
      <Route path="/network/radical-organisations" element={<RadicalList />} />
      <Route path="/network/detail/:id" element={<NetworkDetail />} />
      <Route path="/reports" element={<Reports />} />
      <Route path="/reports/detail/:id" element={<ReportsDetail />} />

      {/* Catch-all route for unknown paths */}
      <Route path="/*" element={<Navigate to="/" />} />
    </Routes>
  );
}

export default AppRoutes;
