// export const getHeader = () => {
//   return fetch("http://43.205.80.119:6120/user/v1/user/topNews").then((res) =>
//     res.json()
//   );
// };

// export const getNewsList = (payload) => {
//   return fetch(
//     `http://43.205.80.119:6120/user/v1/user/newsList?page=${payload?.page}&limit=${payload?.limit}`
//   ).then((res) => res.json());
// };

// export const getInventory = () => {
//   return fetch("https://dummyjson.com/products").then((res) => res.json());
// };

// export const getCustomers = () => {
//   return fetch("https://dummyjson.com/users").then((res) => res.json());
// };
// export const getComments = () => {
//   return fetch("https://dummyjson.com/comments").then((res) => res.json());
// };
import axios from "axios";
import moment from "moment";

// const baseUrl = "http://43.205.80.119:6120/user/v1/user";
// Check if the current page is loaded over HTTPS
const isHttps = window.location.protocol === "https:";
// Set the base URL based on the protocol
const baseUrl = isHttps
  ? "https://radicalwatch.org:6121/user/v1/user"
  : "http://radicalwatch.org:6120/user/v1/user";

const secret_key =
  "JaNdRgUC&F)J@Nc9y$B&E)Hs5v8y/B?Xn2r5u8xfUjXnZr4McQfTjWn(H+MbQeTA?D(G+Kb7x!A%D*G";

class CommonService {
  getNewsList(param) {
    return axios({
      method: "GET",
      url: `${baseUrl}/newsList?${"page=" + (param?.page || 0)}${
        (!!param?.states &&
          "&states=" + encodeURIComponent(`["${param.states}"]`)) ||
        ""
      }${
        (!!param?.districts &&
          "&districts=" + encodeURIComponent(`["${param.districts}"]`)) ||
        ""
      }${(!!param?.search && "&search=" + param?.search) || ""}${
        (!!param?.natureOfViolence &&
          "&natureOfVoilance=" + param.natureOfViolence) ||
        ""
      }${
        (!!param?.identityOfVictim &&
          "&identityOfVictim=" + param.identityOfVictim) ||
        ""
      }${
        (!!param?.casualFactors && "&causalFactors=" + param.casualFactors) ||
        ""
      }${
        (!!param?.startDate &&
          "&startDate=" + moment(param.startDate)?.format("YYYY-MM-DD")) ||
        ""
      }${
        (!!param?.endDate &&
          "&endDate=" + moment(param.endDate)?.format("YYYY-MM-DD")) ||
        ""
      }${"&limit=" + (param?.limit || 10)}${
        (!!param?.state && "&state=" + param.state) || ""
      }`,
      headers: {
        secret_key: secret_key,
      },
    });
  }
  getNewsDetail(param) {
    return axios({
      method: "GET",
      url: `${baseUrl}/newsDetail?newsId=${param}`,
      headers: {
        secret_key: secret_key,
      },
    });
  }
  getReportsList(param) {
    return axios({
      method: "GET",
      url: `${baseUrl}/reportList?${"page=" + (param.page || 0)}${
        "&limit=" + param.limit
      }`,
      headers: {
        secret_key: secret_key,
      },
    });
  }
  getReportDetail(param) {
    return axios({
      method: "GET",
      url: `${baseUrl}/reportDetail?reportId=${param}`,
      headers: {
        secret_key: secret_key,
      },
    });
  }
  getTopNews(param) {
    return axios({
      method: "GET",
      url: `${baseUrl}/topNews`,
      headers: {
        secret_key: secret_key,
      },
    });
  }
  getDashboardCount(param) {
    return axios({
      method: "GET",
      url: `${baseUrl}/dashboardCount`,
      headers: {
        secret_key: secret_key,
      },
    });
  }
  getGraphData(param) {
    return axios({
      method: "GET",
      url: `${baseUrl}/getGraphData`,
      headers: {
        secret_key: secret_key,
      },
    });
  }
  getIndiaMapData(param) {
    return axios({
      method: "GET",
      url: `${baseUrl}/mapData`,
      headers: {
        secret_key: secret_key,
      },
    });
  }
  getNetworkDetail(param) {
    return axios({
      method: "GET",
      url: `${baseUrl}/radicalDetail?radicalId=${param.id}`,
      headers: {
        secret_key: secret_key,
      },
    });
  }
  getStateList(param) {
    return axios({
      method: "GET",
      url: `${baseUrl}/stateList?${"page=" + (param?.page || 0)}${
        "&limit=" + 40
      }`,
      headers: {
        secret_key: secret_key,
      },
    });
  }
  getDistrictList(param) {
    return axios({
      method: "GET",
      url: `${baseUrl}/districtList?page=0&limit=100&stateId=${param.id}`,
      headers: {
        secret_key: secret_key,
      },
    });
  }
  getNetworkList(param) {
    return axios({
      method: "GET",
      url: `${baseUrl}/radicalList?${"page=" + (param.page || 0)}${
        "&limit=" + (param.limit || 10)
      }${(!!param?.search && "&search=" + param?.search) || ""}${
        (!!param?.type && "&type=" + param.type) || ""
      }`,
      headers: {
        secret_key: secret_key,
      },
    });
  }
}
export const commonService = new CommonService();
