import React, { useEffect, useState } from "react";
import { Menu, Drawer, Tooltip } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import { MenuUnfoldOutlined } from "@ant-design/icons";
import "./style.scss";
import { menuItems } from "../../Constants/utils";
import Logo from "../../Assets/Logo.png";
function SideMenu() {
  const location = useLocation();
  const [selectedKeys, setSelectedKeys] = useState("/");
  const [isMobileDevice, setIsMobileDevice] = useState(
    window.innerWidth <= 768
  );
  const [showMoreMenu, setShowMoreMenu] = useState(false);

  useEffect(() => {
    const pathName = location.pathname;
    setSelectedKeys(pathName);

    const handleResize = () => {
      setIsMobileDevice(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [location.pathname]);

  const returnHeading = () => {
    return (
      // <p className="heading font-face-Bold">
      //   Radical <span>Watch</span>
      // </p>
      // <p>
      <img src={Logo} alt="" style={{ width: "100%", height: "100%" }} />
    );
  };

  const navigate = useNavigate();

  const isActive = (item) => {
    return location?.pathname === item.url ? "active" : "";
  };

  const moreMenuItems = menuItems.slice(5);

  const handleMoreMenuClick = () => {
    setShowMoreMenu(true);
  };

  const closeMoreMenu = () => {
    setShowMoreMenu(false);
  };

  return (
    <div className="SideMenu font-face-gm">
      {isMobileDevice ? (
        <div className="mobile-menu-container">
          <Menu
            mode="horizontal"
            onClick={(item) => {
              if (item?.key === "more") {
                handleMoreMenuClick();
              } else {
                navigate(item?.key);
              }
            }}
            selectedKeys={[selectedKeys]}
            className="mobile-menu"
          >
            <Menu.Item key="/">
              <div className={`menuItem ${isActive({ url: "/" })}`}>
                <Tooltip title={returnHeading()}>{returnHeading()}</Tooltip>
              </div>
            </Menu.Item>
            {menuItems.slice(0, 5).map((item) => (
              <Menu.Item key={item.url}>
                <Tooltip title={item.label}>
                  <div className={`menuItem ${isActive(item)}`}>
                    {item.icon}
                  </div>
                </Tooltip>
              </Menu.Item>
            ))}
            <Menu.Item key="more">
              <Tooltip title="More">
                <MenuUnfoldOutlined />
              </Tooltip>
            </Menu.Item>
          </Menu>
          <Drawer
            title="More Menu"
            visible={showMoreMenu}
            onClose={closeMoreMenu}
            width={300}
            placement="right"
            className="more-menu-drawer"
          >
            {moreMenuItems.map((item) => (
              <div
                key={item.url}
                className={`menuItem ${isActive(item)}`}
                onClick={() => {
                  navigate(item.url);
                  closeMoreMenu();
                }}
              >
                {item.icon} {item.label}
              </div>
            ))}
          </Drawer>
        </div>
      ) : (
        <>
          <div className="sidebar">
            <Menu
              className={`SideMenuVertical`}
              mode="vertical"
              onClick={(item) => navigate(item?.key)}
              selectedKeys={[selectedKeys]}
              items={[
                {
                  label: returnHeading(),
                  key: "/",
                },
                ...menuItems.map((item) => ({
                  key: item.url,
                  icon: item.icon,
                })),
              ]}
            />
          </div>
          <div className="hoverDiv">
            <ul>
              {[
                {
                  label: "",
                  key: "",
                },
                ...menuItems,
              ]?.map((item) => {
                return (
                  <li
                    onClick={() => {
                      navigate(item?.url);
                    }}
                    className={isActive(item)}
                  >
                    {item?.label}
                  </li>
                );
              })}
            </ul>
          </div>
        </>
        // </div>
      )}
    </div>
  );
}

export default SideMenu;
