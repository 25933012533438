import { Space } from "antd";
import React from "react";
import { Helmet } from "react-helmet";
import AppHeader from "../../Components/AppHeader";
import "./style.scss";
// import Loader from "../../Components/Loader";
export default function Contributors() {
  return (
    <Space size={20} direction="vertical">
      <AppHeader />
      <Helmet>
        <title>Contributors - Radical Watch </title>
      </Helmet>
      <div className="contributors">
        <div className="heading_wrapper font-face-Bold">
          <p>Contributors</p>
        </div>
      </div>
      {/* <AppFooter /> */}
    </Space>
  );
}
