import React from "react";
import Graph from "../../Components/Graph";
import { graphColorsArray } from "../../Constants/mockData";

function GraphContainer({ data, category, setCardCategory, graphData }) {
  return (
    <div className="graph_container font-face-semiBold">
      <div className="first_row graph_row">
        <div
          className={`wrapper redCard ${
            category === "Crimes" ? "selected" : ""
          }`}
          onClick={() => setCardCategory("Crimes", "/news")}
        >
          <h1>Crimes</h1>
          <p>{data?.crimes}</p>
          <div className="graphContainer">
            <Graph
              chartType="spline"
              data={data?.crimeGraphData}
              color="rgba(255, 7, 58, 0.439216)"
            />
          </div>
        </div>
        <div
          className={`wrapper blueCard ${
            category === "Radical Individuals" ? "selected" : ""
          }`}
          onClick={() =>
            setCardCategory(
              "Radical Individuals",
              "/network/radical-individuals"
            )
          }
        >
          <h1> Radical Individuals</h1>
          <p>{data?.individualRadical}</p>
          <div className="graphContainer">
            <Graph
              chartType="spline"
              data={data?.individualGraphData}
              color="rgba(0, 123, 255, 0.439216)"
            />
          </div>
        </div>
        <div
          className={`wrapper greenCard ${
            category === "Radical Organisations" ? "selected" : ""
          }`}
          onClick={() =>
            setCardCategory(
              "Radical Organisations",
              "/network/radical-organisations"
            )
          }
        >
          <h1>Radical Organisations</h1>
          <p>{data?.organizationsRadicals}</p>
          <div className="graphContainer ">
            <Graph
              chartType="spline"
              data={data?.organizationGraphData}
              color="#018d39"
            />
          </div>
        </div>
        {/* <div
          className={`wrapper greyCard ${
            category === "Radical Events" ? "selected" : ""
          }`}
          onClick={() => setCardCategory("Radical Events")}
        >
          <h1>Radical Events</h1>
          <p>{data?.radicalEvents}</p>
          <div className="graphContainer">
            <Graph
              chartType="spline"
              data={data?.eventsGraphData}
              color="rgba(108, 117, 125, 0.439216)"
            />
          </div>
        </div> */}
      </div>
      <div className="graph_row">
        <div className="wrapper red_wrapper">
          <h1>Victims (Identity wise)</h1>
          <div className="graphContainer">
            <Graph
              chartType="doughnut"
              data={graphData?.identityOfVictim}
              color={graphColorsArray}
            />
          </div>
        </div>
        <div className="wrapper red_wrapper">
          <h1>Perpetrators</h1>
          <div className="graphContainer">
            <Graph
              chartType="doughnut"
              data={graphData?.identityOfPerpetrator}
              color={graphColorsArray}
            />
          </div>
        </div>
        <div className="wrapper red_wrapper">
          <h1>Reasons Behind Violence</h1>
          <div className="graphContainer">
            <Graph
              chartType="doughnut"
              data={graphData?.casualFactors}
              color={graphColorsArray}
            />
          </div>
        </div>
      </div>
      <div className="graph_row">
        <div className="wrapper red_wrapper">
          <h1>States With Highest Number of Incidents</h1>
          <div className="graphContainer">
            <Graph
              chartType="bar"
              data={graphData?.mapData}
              color={graphColorsArray}
            />
          </div>
        </div>
        <div className="wrapper red_wrapper">
          <h1>Nature of Violence</h1>
          <div className="graphContainer">
            {" "}
            <Graph
              chartType="doughnut"
              data={graphData?.natureOfViolance}
              color={graphColorsArray}
            />
          </div>
        </div>

        <div className="wrapper red_wrapper">
          <h1>Party In Power / Incidents</h1>
          <div className="graphContainer">
            {" "}
            <Graph
              chartType={"bar"}
              data={graphData?.partyInPower}
              color={graphColorsArray}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
export default GraphContainer;
