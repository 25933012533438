import "./style.scss";
import { useEffect, useState } from "react";
import ReportProblemOutlinedIcon from "@mui/icons-material/ReportProblemOutlined";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import { useNavigate } from "react-router-dom";
import { commonService } from "../../API";
import Logo from "../../Assets/Logo.png";
export default function AppHeader(props) {
  const [topNews, setTopNews] = useState();
  const [isMobileDevice, setIsMobileDevice] = useState(
    window.innerWidth <= 768
  );
  const navigate = useNavigate();
  const fetchData = async () => {
    const res = await commonService.getTopNews();
    setTopNews(res?.data?.news);
  };
  useEffect(() => {
    fetchData();
    const handleResize = () => {
      setIsMobileDevice(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="appHeader">
      {isMobileDevice && (
        <p className="logo" onClick={() => navigate("/")}>
          {/* Radical <span>Watch</span> */}
          <img src={Logo} alt="" />
        </p>
      )}
      {topNews ? (
        <div className="header font-face-semiBold">
          <p>
            <span>
              {" "}
              <ReportProblemOutlinedIcon />
            </span>
            {topNews?.title}.{" "}
            <span
              className="readMore"
              onClick={() => navigate(`/news/detail/${topNews?._id}`)}
            >
              {" "}
              Read More <ArrowRightAltIcon />
            </span>
          </p>
          {/* <p
        className="readMore"
        onClick={() => navigate("/news/detail/64a0875926197c33b5523035")}
      >
        Read More <ArrowRightAltIcon />
      </p> */}
        </div>
      ) : (
        <div style={{ height: "40px" }}></div>
      )}
    </div>
  );
}
