import { Space } from "antd";
import { useEffect, useState } from "react";
import { commonService } from "../../API";
import AppHeader from "../../Components/AppHeader";
import { useNavigate, useParams } from "react-router-dom";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import "./style.scss";
import Loader from "../../Components/Loader";
import AppFooter from "../../Components/AppFooter";
import moment from "moment";
import { Helmet } from "react-helmet";
function ReportsDetail() {
  const [loading, setLoading] = useState(false);
  const [data, setDataSource] = useState([]);
  const navigate = useNavigate();
  const { id } = useParams();
  // const targetElementRef = useRef(null);
  const scrollToElement = (id) => {
    console.log(id, "idd");
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  const fetchData = async () => {
    setLoading(true);
    const reportDetail = await commonService.getReportDetail(id);
    setDataSource(reportDetail?.data);
    setLoading(false);
  };

  useEffect(() => {
    try {
      fetchData();
    } catch {
      setLoading(false);
    }
  }, []);

  return (
    <Space size={20} direction="vertical">
      {loading ? (
        <Loader />
      ) : (
        <div className=" network news networkDetail " id="reportDetail">
          <Helmet>
            <title>Reports - Radical Watch </title>
          </Helmet>
          <AppHeader />
          <div className="news_wrapper">
            <div className="heading">
              <div className="heading_wrapper font-face-Bold">
                <p> Report</p>
              </div>

              {/* <div className="searchContainer">
                <Input
                  placeholder="Search for Individuals/ Organisations..."
                  suffix={<SearchOutlined />}
                />
              </div> */}
            </div>
            <div className="titleHeader font-face-Bold">
              <p> {data?.result?.title}</p>
            </div>

            <div className="contentContainer">
              <div className="leftContent">
                <div className="thumbNailContainer">
                  <img src={data?.result?.logo} alt="thumbNail" />
                </div>
                {data?.result?.tableContent?.length ? (
                  <div className="tableofContent">
                    <div className="heading_wrapper font-face-Bold">
                      <p> Table of Contents</p>
                    </div>
                    <div className="content">
                      <ul>
                        {data?.result?.tableContent?.map((item) => {
                          return (
                            <li
                              id={item?.id}
                              onClick={() => {
                                scrollToElement(item?.id);
                              }}
                            >
                              {item?.title}
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  </div>
                ):null}
                {(data?.result?.highlights?.length ||
                  data?.result?.tableContent?.length) ? (
                  <div className="summary">
                    {data?.result?.highlights?.length ? (
                      <div className="keyHighlights">
                        <p className="font-face-Bold">Hightlights:</p>
                        <ul>
                          {data?.result?.highlights?.map((item) => {
                            return <li>{item}</li>;
                          })}
                        </ul>
                      </div>
                    ):null}
                    {data?.result?.tableContent?.length &&
                      data?.result?.tableContent?.map((item) => {
                        return (
                          <div
                            className="description"
                            id={item.id}
                            key={item?.id}
                          >
                            <p className="font-face-Bold title">{item.title}</p>
                            <br />
                            <p>{item?.description}</p>
                            <br />
                            <div className="imageContainer">
                              <img src={item?.media} alt="media" />
                            </div>
                            {/* <ul>
                          {data?.executiveSummary?.map((item) => {
                            return <li>{item}</li>;
                          })}
                        </ul> */}
                          </div>
                        );
                      })}
                  </div>
                ):null}
              </div>
              <div className="rightContent">
                <div className="relatedConnections font-face-Bold">
                  <p className="number ">256</p>
                  <p className="related">Related Connections</p>
                </div>
                <div className="individualContent">
                  <div className="individualHeading font-face-Bold">
                    <p className="number">156</p> <p>INDIVIDUALS</p>
                  </div>
                  <div className="individualList font-face-regular">
                    <li>Ved Bhasin</li>
                    <li>Ghulam Nabi Fai</li>
                    <li>Ved Bhasin</li>
                  </div>
                </div>

                <div className="individualContent individualorganisation">
                  <div className="individualHeading font-face-Bold">
                    <p className="number">100</p> <p>ORGANISATIONS</p>
                  </div>
                  <div className="individualList">
                    <li>Ved Bhasin</li>
                    <li>Ghulam Nabi Fai</li>
                  </div>
                </div>
                <div className="relatedMedia">
                  <div className="heading font-face-Bold">
                    <div>Related Media</div>
                    <div className="viewMore" onClick={() => navigate("/news")}>
                      View More <ArrowRightAltIcon />
                    </div>
                  </div>
                  <div className="media">
                    {data?.result?.media?.map((item) => {
                      return (
                        <div
                          className="imgContainer"
                          // onClick={() => navigate(`/news/detail/${item._id}`)}
                        >
                          <img src={item?.url} alt="media" />
                        </div>
                      );
                    })}
                  </div>
                </div>
                <div className="relatedMedia">
                  <div className="heading font-face-Bold">
                    <div>Related Reports</div>
                  </div>
                  <div className="media">
                    {data?.result?.relatesReports?.map((item) => {
                      return (
                        <div
                          className="imgContainer"
                          onClick={() => navigate(`/news/${item._id}`)}
                        >
                          <img src={item.url} alt="media" />
                        </div>
                      );
                    })}
                  </div>
                </div>
                <div className="relatedMedia">
                  <div className="heading font-face-Bold">
                    <div>Related News</div>
                  </div>
                  <div className="media">
                    {data?.relatesNews?.map((item) => {
                      return (
                        <div>
                          <p
                            className="link"
                            onClick={() => navigate(`/news/detail/${item._id}`)}
                          >
                            {item?.title}
                          </p>
                          <p>
                            {moment(item.createdAt).format("DD MMMM, YYYY")}
                          </p>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <AppFooter />{" "}
        </div>
      )}
    </Space>
  );
}
export default ReportsDetail;
