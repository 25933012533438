import React, { useEffect, useRef } from "react";
import Chart from "chart.js/auto";

const Doughnut = ({ data }) => {
  const doughnutChartRef = useRef(null);

  useEffect(() => {
    let doughnutChart;

    // Doughnut Chart
    const doughnutChartCtx = doughnutChartRef.current.getContext("2d");
    doughnutChart = new Chart(doughnutChartCtx, {
      type: "doughnut",
      data: data,
      options: {
        responsive: true,
        plugins: {
          legend: {
            display: false,
            position: "bottom",
          },
          title: {
            display: false,
          },
          tooltip: {
            // enabled: false, // Disable the tooltip on hover
          },
        },
        cutout: "70%",
        elements: {
          arc: {
            borderWidth: 0,
          },
        },
        aspectRatio: 1.2,
        hover: {
          mode: null, // Disables the tooltip on hover
        },
      },
    });

    return () => {
      doughnutChart.destroy();
    };
  }, []);

  return (
    <div className="doughnutChart">
      <canvas ref={doughnutChartRef} />
    </div>
  );
};

export default Doughnut;
