import React, { useEffect, useRef } from "react";
import Chart from "chart.js/auto";

const Bar = ({ data }) => {
  const barChartRef = useRef(null);

  useEffect(() => {
    let barChart;

    // Bar Chart
    const barChartCtx = barChartRef.current.getContext("2d");
    barChart = new Chart(barChartCtx, {
      type: "bar",
      data: data,
      options: {
        responsive: true,
        plugins: {
          legend: {
            display: false,
            position: "top",
          },
          title: {
            display: false,
          },
        },
        scales: {
          x: {
            // display: false,
            grid: {
              display: false,
            },
            ticks: {
              display: false,
            },
          },
          y: {
            display: false,
            grid: {
              display: false,
            },
            ticks: {
              display: false,
            },
          },
        },
        elements: {
          bar: {
            borderRadius: 8,
            barThickness: 3,
          },
        },
      },
    });

    return () => {
      barChart.destroy();
    };
  }, []);

  return (
    <div className="barChart">
      <canvas ref={barChartRef} />
    </div>
  );
};

export default Bar;
