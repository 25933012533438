import React, { useState } from "react";
import { Space } from "antd";
import AppHeader from "../../Components/AppHeader";
import AppFooter from "../../Components/AppFooter";
import NetworkCard from "./NetworkCard";
import { useEffect } from "react";
import { commonService } from "../../API";
import { Helmet } from "react-helmet";
import Loader from "../../Components/Loader";

function RadicalList() {
  const [radicalListData, setRadicalListData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const radicalList = async () => {
    setIsLoading(true);
    const radicalIndividual = window.location.pathname.includes("individuals");
    if (radicalIndividual) {
      const data = await commonService.getNetworkList({ type: "individual" });
      setRadicalListData({
        heading: "Radical Individuals",
        data: data?.data?.radicals,
      });
    } else {
      const data = await commonService.getNetworkList({ type: "organisation" });
      setRadicalListData({
        heading: "Radical Organisations",
        data: data?.data?.radicals,
      });
    }
    setIsLoading(false);
  };

  const alphabets = Array.from({ length: 26 }, (_, index) =>
    String.fromCharCode(65 + index)
  );

  useEffect(() => {
    try {
      radicalList();
    } catch {
      setIsLoading(false);
    }
  }, []);
  console.log(radicalListData, "radicalListData");
  return (
    <Space size={20} direction="vertical">
      {isLoading ? (
        <Loader />
      ) : (
        <div className="">
          <Helmet>
            <title>Network - Radical Watch </title>
          </Helmet>
          <AppHeader />

          <div className="radical-list">
            <div className="heading">{radicalListData?.heading}</div>
            {radicalListData?.data?.length ? (
              <div>
                {alphabets?.map((ele) => {
                  const filteredData = radicalListData?.data?.filter(
                    (internalItem) => internalItem?.title?.startsWith(ele)
                  );

                  if (filteredData?.length > 0) {
                    return (
                      <div className="alphabetical-list" key={ele}>
                        <p className="subHeading">{ele}</p>
                        <div className="list-container">
                          {filteredData.map((internalItem) => (
                            <NetworkCard
                              key={internalItem._id}
                              logo={internalItem.logo}
                              heading={internalItem.title}
                              subHeading={"Organisation Name"}
                              path={`/network/detail/${internalItem._id}`}
                            />
                          ))}
                        </div>
                      </div>
                    );
                  }

                  return null;
                })}
              </div>
            ) : (
              <div className="no_data"> No Data Found</div>
            )}
          </div>
          <AppFooter />
        </div>
      )}
    </Space>
  );
}
export default RadicalList;
