// menuItems.js

import { ReactComponent as HomeIcon } from "../Assets/Home.svg";
import { ReactComponent as DatabaseIcon } from "../Assets/Database.svg";
import { ReactComponent as NewsIcon } from "../Assets/News.svg";
import { ReactComponent as NetworkIcon } from "../Assets/Network.svg";
import { ReactComponent as ReportsIcon } from "../Assets/ReportsIcon.svg";
import { ReactComponent as AboutUs } from "../Assets/aboutUs.svg";
import { ReactComponent as SupportUs } from "../Assets/supportUs.svg";
import { ReactComponent as Contributors } from "../Assets/contributors.svg";

export const menuItems = [
  { label: "Home", url: "/", icon: <HomeIcon />, key: "home" },
  { label: "News", url: "/news", icon: <NewsIcon />, key: "news" },
  {
    label: "Database",
    url: "/database",
    icon: <DatabaseIcon />,
    key: "database",
  },
  { label: "Network", url: "/network", icon: <NetworkIcon />, key: "network" },
  { label: "Reports", url: "/reports", icon: <ReportsIcon />, key: "reports" },
  {
    label: "Contributors",
    url: "/contributors",
    icon: <Contributors />,
    key: "contributors",
  },
  { label: "About Us", url: "/about-us", icon: <AboutUs />, key: "about-us" },
  {
    label: "Support Us",
    url: "/support-us",
    icon: <SupportUs />,
    key: "support-us",
  },
];
