import { Space } from "antd";
import AppHeader from "../../Components/AppHeader";
import "./style.scss";
import NetworkCard from "./NetworkCard";
import AppFooter from "../../Components/AppFooter";
import { useEffect, useState } from "react";
import { commonService } from "../../API";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
function Network() {
  const [individualList, setIndividualList] = useState([]);
  const [organisationList, setOrganisationList] = useState([]);
  const navigate = useNavigate();
  const fetchData = async () => {
    const getIndividualList = await commonService.getNetworkList({
      limit: 10,
      type: "individual",
    });
    const getOrganizationList = await commonService.getNetworkList({
      limit: 10,
      type: "organisation",
    });
    setIndividualList(getIndividualList?.data?.radicals);
    setOrganisationList(getOrganizationList?.data?.radicals);
  };
  useEffect(() => {
    try {
      fetchData();
    } catch {}
  }, []);
  return (
    <Space size={20} direction="vertical">
      <div className=" network news">
        <AppHeader />
        <Helmet>
          <title>Network - Radical Watch </title>
        </Helmet>
        <div className="news_wrapper">
          <div className="heading">
            <div className="heading_wrapper font-face-Bold">
              <p> Network</p>
            </div>
            {/* <div className="searchContainer">
              <p className="searchHeadng font-face-Bold">Search</p>
              <Input
                placeholder="Search for Individuals/ Organisations..."
                suffix={<SearchOutlined />}
              />
            </div> */}
          </div>
          <div className="networkList">
            <div className="radicalNetwork">
              <p className="subHeading font-face-Bold">Radical Individuals</p>
              <div className="radicalNetworkList">
                {individualList?.map((item) => {
                  return (
                    <NetworkCard
                      logo={item?.logo}
                      heading={item?.title}
                      subHeading={"Organisation Name"}
                      path={`/network/detail/${item?._id}`}
                      socialLinks={item?.socialLinks}
                    />
                  );
                })}
              </div>

              <div className="seeFull">
                <p onClick={() => navigate("/network/radical-individuals")}>
                  See Full List &gt;&gt;
                </p>
              </div>
            </div>
            <div className="radicalNetwork">
              <p className="subHeading font-face-Bold">Radical Organisations</p>
              <div className="radicalNetworkList">
                {organisationList.map((item) => {
                  return (
                    <NetworkCard
                      logo={item.logo}
                      heading={item.title}
                      subHeading={"Founder's Name"}
                      path={`/network/detail/${item._id}`}
                      socialLinks={item?.socialLinks}
                    />
                  );
                })}
              </div>
              <div className="seeFull">
                <p onClick={() => navigate("/network/radical-organisations")}>
                  See Full List &gt;&gt;
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <AppFooter />
    </Space>
  );
}
export default Network;
