import { Select } from "antd";
import "./style.scss";
export default function DropDown(props) {
  const { label, dropDownOptions, name, handleChange, value } = props;
  // const handleChange = (value) => {};
  return (
    <Select
      // defaultValue="lucy"
      placeholder={label}
      name={name}
      value={value}
      // style={{ width: 200, color: "#000", border: "1px solid #6C757D" }}
      className="dropDownSelect font-face-regular"
      onChange={handleChange}
      options={dropDownOptions.map((item) => {
        return { value: item["_id"], label: item[name] };
      })}
    />
  );
}
