import { Space } from "antd";
import React from "react";
import { Helmet } from "react-helmet";
import AppFooter from "../../Components/AppFooter";
import AppHeader from "../../Components/AppHeader";
import "./style.scss";
// import Loader from "../../Components/Loader";
export default function AboutUs() {
  return (
    <Space size={20} direction="vertical">
      <AppHeader />
      <Helmet>
        <title>About Us - Radical Watch </title>
      </Helmet>
      <div className="about-us">
        <div className="heading_wrapper font-face-Bold">
          <p> About Us</p>
        </div>
        <div className="about-content">
          {" "}
          <p>
            Radical Watch is a pioneering independent research initiative,
            vigilantly monitoring the reports of attacks occurring within India
            or aimed at India, whether instigated domestically or orchestrated
            from foreign lands. We are committed to shedding light on the
            ideologies, institutions, and individuals responsible for acts of
            violence and injustice that disturb the peaceful fabric of society.
          </p>
          <br />{" "}
          <p>
            Our work comprises of aggregating content and information from a
            robust network that includes dedicated journalists, vigilant
            activists, and credible public sources. These sources encompass
            reputable news organisations that provide real-time, reliable data
            on incidents. Adherence to the highest standards of ethical
            journalism is a cornerstone of Radical Watch. We strive to promote
            the free exchange of information, ensuring it remains accurate,
            fair, and thorough.
          </p>
          <br />
          <p>
            {" "}
            Radical Watch has diligently documents all verifiable incidents of
            hate crimes in our expansive and continually updated database. These
            records not only include reported incidents but also consist of
            in-depth fact-finding investigations and valuable reports from civil
            society. Our approach creates an efficient, easy-to-use model that
            aims to democratise access to critical information.{" "}
          </p>
          <br />
          <p>
            We firmly believe in making essential information universally
            accessible, especially to those tirelessly working towards a more
            peaceful, just society. Our resources serve as indispensable tools
            for academicians, journalists, and activists worldwide, providing
            them with the information needed to understand, raise awareness, and
            combat hate crimes in India.
          </p>
        </div>
      </div>
      <AppFooter />
    </Space>
  );
}
