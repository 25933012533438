import React from "react";
import { useNavigate } from "react-router-dom";
import "./style.scss";
function RecentPosts(props) {
  const { heading, path, data } = props;
  const navigate = useNavigate();
  return (
    <div className="recentNews">
      <div className="heading_wrapper font-face-Bold">
        <p
          onClick={() => {
            navigate(path);
          }}
        >
          {heading}
        </p>
      </div>

      <div className="news_wrapper">
        {data?.map((item) => {
          return (
            <div
              className="postContainer"
              onClick={() => {
                navigate(`${path}/detail/${item?._id}`);
              }}
            >
              <img src={item.logo} alt="logo" />
              <p>{item.title}</p>
            </div>
          );
        })}
      </div>
    </div>
  );
}
export default RecentPosts;
