import { ReactComponent as TwitterIcon } from "../../Assets/twitter.svg";
import { ReactComponent as FacebookIcon } from "../../Assets/facebook.svg";
import { ReactComponent as MailIcon } from "../../Assets/mail.svg";
import { ReactComponent as InstagramIcon } from "../../Assets/instagram.svg";
import { ReactComponent as TelegramIcon } from "../../Assets/telegram.svg";

function AppFooter() {
  return (
    <div className="AppFooter">
      {/* <Typography.Link href="tel:+123456789">+123456789</Typography.Link>
      <Typography.Link href="https://www.google.com" target={"_blank"}>
        Privacy Policy
      </Typography.Link>
      <Typography.Link href="https://www.google.com" target={"_blank"}>
        Terms of Use
      </Typography.Link> */}
      <div className="footer">
        <p className="radical">Radical Watch</p>
        <p className="p_tag">Tracking crime & Ensuring safety</p>
        <div className="icons">
          <TwitterIcon />
          <FacebookIcon />
          <InstagramIcon />
          <TelegramIcon />
          <MailIcon />
        </div>
      </div>
    </div>
  );
}
export default AppFooter;
