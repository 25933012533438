import { Space } from "antd";
import { useEffect, useState } from "react";
import { commonService } from "../../API";
import AppHeader from "../../Components/AppHeader";
import { useNavigate, useParams } from "react-router-dom";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import moment from "moment";
import "./style.scss";
import Loader from "../../Components/Loader";
import AppFooter from "../../Components/AppFooter";
import { Helmet } from "react-helmet";
function NewsDetail() {
  const [loading, setLoading] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const navigate = useNavigate();
  const { id } = useParams();

  const fetchData = async () => {
    setLoading(true);
    const detail = await commonService.getNewsDetail(id);
    setDataSource(detail?.data);
    setLoading(false);
  };
  useEffect(() => {
    try {
      fetchData();
    } catch {
      setLoading(false);
    }
  }, []);
  return (
    <Space size={20} direction="vertical">
      {loading ? (
        <Loader />
      ) : (
        <div className="news">
          <Helmet>
            <title>News - Radical Watch </title>
          </Helmet>
          <AppHeader />
          <div className="news_details">
            <div className="heading_wrapper font-face-Bold">
              <p> News</p>
            </div>
            <div className="detailContainer">
              <div className="leftContainer">
                <div className="title font-face-Bold">
                  {dataSource?.result?.title}
                </div>
                <div className="description font-face-regular">
                  {dataSource?.result?.description}
                </div>
                <div className="information">
                  <div className="heading font-face-Bold">
                    OTHER INFORMATION
                  </div>
                  <div className="content font-face-regular">
                    <div className="leftContent">
                      <p>
                        Source of information:{" "}
                        {
                          dataSource?.result?.otherInformation
                            ?.sourceOfInformation
                        }
                      </p>
                      <p>
                        Number of victims:{" "}
                        {dataSource?.result?.otherInformation?.numberOfVictim}
                      </p>
                      <p>
                        Location of incident:{" "}
                        {
                          dataSource?.result?.otherInformation
                            ?.locationOfIncident
                        }
                      </p>
                      <p>
                        perpetrator Organization:{" "}
                        {
                          dataSource?.result?.otherInformation
                            ?.perpetratorOrganization
                        }
                      </p>
                      <p>
                        Investigating agency:{" "}
                        {
                          dataSource?.result?.otherInformation
                            ?.investigationAgency
                        }
                      </p>
                      <p>
                        Role of police:{" "}
                        {dataSource?.result?.otherInformation?.roleOfPolice}
                      </p>
                      <p>
                        Charges Filed:{" "}
                        {dataSource?.result?.otherInformation?.charedFiled}
                      </p>
                      <p>
                        judgement Reference:{" "}
                        {
                          dataSource?.result?.otherInformation
                            ?.judementReference
                        }
                      </p>
                    </div>
                    <div className="rightContent">
                      <p>
                        Identity of victim:{" "}
                        {dataSource?.result?.otherInformation?.identityOfVictim}
                      </p>
                      <p>
                        Was cross FIR filed:{" "}
                        {dataSource?.result?.otherInformation?.crossFirFiled}
                      </p>
                      <p>
                        Details of the FIR:{" "}
                        {dataSource?.result?.otherInformation?.defailsOfFIR}
                      </p>
                      <p>
                        Victim's lawyer:{" "}
                        {dataSource?.result?.otherInformation?.victimLawyer}
                      </p>
                      <p>
                        Current status:{" "}
                        {dataSource?.result?.otherInformation?.currentStatus}
                      </p>
                      {/* <p>
                        Identity of Perpetrators:{" "}
                        {dataSource?.otherInformation?.judementReference}
                      </p> */}
                      <p>
                        Remarks: {dataSource?.result?.otherInformation?.remarks}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="rightContainer">
                <div className="imgContainer">
                  <img src={dataSource?.result?.logo} alt="logo" />
                </div>
                <div className="information">
                  <div className="heading font-face-Bold">KEY INFORMATION</div>
                  <div className="content font-face-regular">
                    <div className="leftContent">
                      <div>
                        <p> Date of incident:</p>{" "}
                        <p>
                          {moment(
                            dataSource?.result?.keyInformation?.incidentDate
                          ).format("MMMM DD, YYYY") || "- - -"}
                        </p>
                      </div>
                      <div>
                        <p>Nature of Violence: </p>
                        <p>
                          {dataSource?.result?.keyInformation
                            ?.natureOfViolance || "- - -"}
                        </p>
                      </div>
                      <div>
                        <p>Causal factors: </p>
                        <p>
                          {" "}
                          {dataSource?.result?.keyInformation?.casualFactors ||
                            "- - -"}
                        </p>
                      </div>
                      <div>
                        <p>No. of People Injured: </p>
                        <p>
                          {dataSource?.result?.keyInformation?.peopleInjured ||
                            "- - -"}
                        </p>
                      </div>
                      <div>
                        <p>Name of the victim: </p>
                        <p>
                          {dataSource?.result?.keyInformation?.victimName ||
                            "- - -"}
                        </p>
                      </div>
                      <div>
                        <p>Name of Perpetrator: </p>
                        <p>
                          {dataSource?.result?.keyInformation
                            ?.perpetratorName || "- - -"}
                        </p>
                      </div>
                      <div>
                        <p>State:</p>
                        <p>
                          {dataSource?.result?.state || "- - -"}
                        </p>
                      </div>
                      <div>
                        <p>District/City:</p>
                        <p>
                          {dataSource?.result?.district ||
                            "- - -"}
                        </p>
                      </div>
                      <div>
                        <p>Party In Power/Incidents: </p>
                        <p>
                          {dataSource?.result?.keyInformation?.partyInPower ||
                            "- - -"}
                        </p>
                      </div>
                    </div>
                    {/* <div className="rightContent">
                      <p>
                        {moment(
                          dataSource?.result?.keyInformation?.incidentDate
                        ).format("MMMM DD, YYYY") || "- - -"}
                      </p>
                      <p>
                        {dataSource?.result?.keyInformation?.natureOfViolance ||
                          "- - -"}
                      </p>
                      <p>
                        {dataSource?.result?.keyInformation?.casualFactors ||
                          "- - -"}
                      </p>
                      <p>
                        {dataSource?.result?.keyInformation?.peopleInjured ||
                          "- - -"}
                      </p>
                      <p>{dataSource?.result?.keyInformation?.victimName}</p>
                      <p>
                        {dataSource?.result?.keyInformation?.perpetratorName}
                      </p>
                      <p> {dataSource?.result?.keyInformation?.state}</p>
                      <p>{dataSource?.result?.keyInformation?.district}</p>
                      <p>{dataSource?.result?.keyInformation?.partyInPower}</p>
                    </div> */}
                  </div>
                  <div className="information mediaLink">
                    <div className="heading font-face-Bold">MEDIA LINK</div>
                    <div className="media">
                      {dataSource?.result?.mediaLink?.map((item) => {
                        return (
                          <a href={item.link} target="_blank">
                            <p className="font-face-Bold"> {item.name}</p>
                          </a>
                        );
                      })}
                    </div>
                  </div>
                </div>
               {dataSource?.result?.media?.length? <div className="relatedMedia">
                  <div className="heading font-face-Bold">
                    <div>Related Media</div>
                    <div className="viewMore" onClick={() => navigate("/news")}>
                      View More <ArrowRightAltIcon />
                    </div>
                  </div>

                  <div className="media">
                    {dataSource?.result?.media?.map((item) => {
                      return (
                        <div
                          className="imgContainer"
                          // onClick={() => navigate(`/news/detail/${item._id}`)}
                        >
                          <img src={item.url} alt="media" />
                        </div>
                      );
                    })}
                  </div>
                </div>:null}
                {dataSource?.relatesReports?.length ?<div className="relatedMedia" id="relatedReports">
                  <div className="heading font-face-Bold">
                    <div>Related Reports</div>
                  </div>
                  <div className="media">
                    {dataSource?.relatesReports?.map((item) => {
                      return (
                        <div
                          className="imgContainer"
                          onClick={() => navigate(`/news/${item._id}`)}
                        >
                          <img src={item.url} alt="media" />
                        </div>
                      );
                    })}
                  </div>
                </div>:null}
               {dataSource?.relatesNews?.length? <div className="relatedMedia">
                  <div className="heading font-face-Bold">
                    <div>Related News</div>
                  </div>
                  <div className="media">
                    {dataSource?.relatesNews?.map((item) => {
                      return (
                        <div>
                          <p
                            className="link"
                            onClick={() => navigate(`/news/detail/${item._id}`)}
                          >
                            {item?.title}
                          </p>
                          <p>
                            {moment(item.createdAt).format("DD MMMM, YYYY")}
                          </p>
                        </div>
                      );
                    })}
                  </div>
                </div>:null}
              </div>
            </div>
          </div>
          <AppFooter />
        </div>
      )}
    </Space>
  );
}
export default NewsDetail;
