import React from "react";
import Bar from "./Bar";
import Doughnut from "./Doughnut";
import Spline from "./Spline";

const Graph = ({ chartType, data, color }) => {
  const manipulateData = (getData) => {
    const labels = getData?.map((item) => {
      return item._id;
    });

    const data = getData?.map((item) => {
      return item.count;
    });
    let obj = {};
    if (chartType === "bar" || chartType === "doughnut") {
      obj = {
        labels: labels,
        datasets: [
          {
            data: data,
            backgroundColor: color,
          },
        ],
      };
    } else {
      obj = {
        labels: labels,
        datasets: [
          {
            data: data,
            borderColor: color,
            backgroundColor: "transparent",
            tension: 0.5,
            pointRadius: 2, // Remove data point radius
            pointHoverRadius: 2, // Remove data point hover radius
          },
        ],
      };
    }
    return obj;
  };

  const renderChart = () => {
    switch (chartType) {
      case "bar":
        return <Bar data={manipulateData(data)} />;
      case "spline":
        return <Spline data={manipulateData(data)} />;
      case "doughnut":
        return <Doughnut data={manipulateData(data)} />;
      default:
        return null;
    }
  };

  return <div>{renderChart()}</div>;
};

export default Graph;
