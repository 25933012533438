import { SearchOutlined } from "@ant-design/icons";
import { Input, Space } from "antd";
import React, { useEffect, useState } from "react";
import AppHeader from "../../Components/AppHeader";
import { imageArray } from "../../Constants/mockData";
import { ReactComponent as GlobeIcon } from "../../Assets/globe-outline.svg";
import { ReactComponent as TwitterIcon } from "../../Assets/twitter.svg";
import { ReactComponent as FacebookIcon } from "../../Assets/facebook.svg";
import { ReactComponent as LinkedInIcon } from "../../Assets/linkedin.svg";
import { ReactComponent as LinkIcon } from "../../Assets/link-outline.svg";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";

import "./style.scss";
import { Link, useNavigate, useParams } from "react-router-dom";
import { commonService } from "../../API";
import Loader from "../../Components/Loader";
import AppFooter from "../../Components/AppFooter";
import { Helmet } from "react-helmet";
function NetworkDetail() {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});
  const navigate = useNavigate();
  // useEffect(() => {
  //   // getNewsList(payload).then((res) => {
  //   setData(getRadicalDetail?.result);
  //   // });

  // }, []);
  const { id } = useParams();
  const fetchData = async () => {
    setLoading(true);
    const networkDetail = await commonService.getNetworkDetail({ id });
    setData(networkDetail?.data?.result);
    setLoading(false);
  };
  useEffect(() => {
    try {
      fetchData();
    } catch {
      setLoading(false);
    }
  }, []);
  return (
    <Space size={20} direction="vertical">
      {loading ? (
        <Loader />
      ) : (
        <div className=" network news networkDetail" id="networkDetail">
          <Helmet>
            <title>Network - Radical Watch </title>
          </Helmet>
          <AppHeader />
          <div className="news_wrapper">
            <div className="heading">
              <div className="heading_wrapper font-face-Bold">
                <p>
                  {/* <span> */}
                  <Link to="/network"> Network </Link>
                  {/* </span>{" "} */}
                  {">"} {data?.type}s
                </p>
              </div>
              {/* <div className="searchContainer">
                <Input
                  placeholder="Search for Individuals/ Organisations..."
                  suffix={<SearchOutlined />}
                />
              </div> */}
            </div>
            <div className="detailHeader">
              <div className="networkCard ">
                <div className="imgContainer">
                  <img src={data.logo} alt="avatar" />
                </div>
                <div className="detailName">
                  <p className="name">{data?.title}</p>
                  <div className="networkIcons">
                    <a
                      href={data?.socialLinks?.website}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <GlobeIcon />
                    </a>
                    <a
                      href={data?.socialLinks?.twitter}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <TwitterIcon />
                    </a>
                    <a
                      href={data?.socialLinks?.facebook}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FacebookIcon />
                    </a>
                    <a
                      href={data?.socialLinks?.linkedin}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <LinkedInIcon />
                    </a>
                    <a
                      // href={data?.socialLinks?.website}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <LinkIcon />
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div className="contentContainer">
              <div className="leftContent">
                <div className="keyHighlights">
                  <p className="font-face-Bold">Key Hightlights</p>
                  <ul>
                    <li>{data?.keyHighlight}</li>
                  </ul>
                </div>
                <div className="description">{data?.description}</div>
              </div>
              <div className="rightContent">
                <div className="relatedConnections font-face-Bold">
                  <p className="number ">256</p>
                  <p className="related">Related Connections</p>
                </div>
                <div className="individualContent">
                  <div className="individualHeading font-face-Bold">
                    <p className="number">156</p> <p>INDIVIDUALS</p>
                  </div>
                  <div className="individualList ">
                    <li>Ved Bhasin</li>
                    <li>Ghulam Nabi Fai</li>
                    <li>Ved Bhasin</li>
                    <li>Ved Bhasin</li>
                    <li>Ghulam Nabi Fai</li>
                    <li>Ved Bhasin</li> <li>Ved Bhasin</li>
                    <li>Ghulam Nabi Fai</li>
                    <li>Ved Bhasin</li>
                    <li>Ved Bhasin</li>
                    <li>Ghulam Nabi Fai</li>
                    <li>Ved Bhasin</li> <li>Ved Bhasin</li>
                    <li>Ghulam Nabi Fai</li>
                    <li>Ved Bhasin</li>
                    <li>Ved Bhasin</li>
                    <li>Ghulam Nabi Fai</li>
                    <li>Ved Bhasin</li> <li>Ved Bhasin</li>
                    <li>Ghulam Nabi Fai</li>
                    <li>Ved Bhasin</li>
                    <li>Ved Bhasin</li>
                    <li>Ghulam Nabi Fai</li>
                    <li>Ved Bhasin</li> <li>Ved Bhasin</li>
                    <li>Ghulam Nabi Fai</li>
                    <li>Ved Bhasin</li>
                    <li>Ved Bhasin</li>
                    <li>Ghulam Nabi Fai</li>
                    <li>Ved Bhasin</li> <li>Ved Bhasin</li>
                    <li>Ghulam Nabi Fai</li>
                    <li>Ved Bhasin</li>
                  </div>
                </div>

                <div className="individualContent individualorganisation">
                  <div className="individualHeading font-face-Bold">
                    <p className="number">100</p> <p>ORGANISATIONS</p>
                  </div>
                  <div className="individualList ">
                    <li>Ved Bhasin</li>
                    <li>Ghulam Nabi Fai</li>
                    <li>Ved Bhasin</li>
                    <li>Ved Bhasin</li>
                    <li>Ghulam Nabi Fai</li>
                    <li>Ved Bhasin</li> <li>Ved Bhasin</li>
                    <li>Ghulam Nabi Fai</li>
                    <li>Ved Bhasin</li>
                    <li>Ved Bhasin</li>
                    <li>Ghulam Nabi Fai</li>
                    <li>Ved Bhasin</li> <li>Ved Bhasin</li>
                    <li>Ghulam Nabi Fai</li>
                    <li>Ved Bhasin</li>
                    <li>Ved Bhasin</li>
                    <li>Ghulam Nabi Fai</li>
                    <li>Ved Bhasin</li> <li>Ved Bhasin</li>
                    <li>Ghulam Nabi Fai</li>
                    <li>Ved Bhasin</li>
                    <li>Ved Bhasin</li>
                    <li>Ghulam Nabi Fai</li>
                    <li>Ved Bhasin</li> <li>Ved Bhasin</li>
                    <li>Ghulam Nabi Fai</li>
                    <li>Ved Bhasin</li>
                    <li>Ved Bhasin</li>
                    <li>Ghulam Nabi Fai</li>
                    <li>Ved Bhasin</li> <li>Ved Bhasin</li>
                    <li>Ghulam Nabi Fai</li>
                    <li>Ved Bhasin</li>
                  </div>
                </div>
                {data?.media?.length ?<div className="relatedMedia">
                  <div className="heading font-face-Bold">
                    <div>Related Media</div>
                    <div className="viewMore">
                      <Link >
                        {" "}
                        View More <ArrowRightAltIcon />
                      </Link>
                    </div>
                  </div>
                  <div className="media">
                    {data?.media?.map((item) => {
                      return (
                        <div className="imgContainer" >
                          <img src={item.url} alt="media"/>
                        </div>
                      );
                    })}
                  </div>
                </div>:null}
              </div>
            </div>
          </div>
          <AppFooter />
        </div>
      )}
    </Space>
  );
}
export default NetworkDetail;
