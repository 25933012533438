import React from "react";
import "./style.scss";
// function IndiaMapColorCoder({ category }) {
//   const returnClass = () => {
//     switch (category) {
//       case "Crimes":
//         return "crime";
//       case "Radical Individuals":
//         return "individual";
//       case "Radical Organisations":
//         return "organization";
//       case "Radical Events":
//         return "event";
//       default:
//     }
//   };
//   return (
// <div className="IndiaMapColorCoder">
//   <div className={`colors crime`}></div>
//   <div className="numbers">
//     <p>0</p>
//     <p>200</p>
//     <p>400</p>
//     <p>600</p>
//     <p>800</p>
//     <p>1,000+</p>
//     {/* <p>12,00</p>
//     <p>14,00+</p> */}
//   </div>
// </div>
//   );
// }
function IndiaMapColorCoder() {
  const rangeStart = 0;
  const rangeEnd = 1000;
  const numberOfPieces = 20;
  const divisionSize = (rangeEnd - rangeStart) / numberOfPieces;

  const breakpoints = [];

  for (let i = 0; i <= numberOfPieces; i++) {
    const breakpoint = i * divisionSize;
    breakpoints.push(breakpoint);
  }

  const breakpointElements = breakpoints.map((breakpoint, index) => {
    if (index === numberOfPieces) {
      return <p key={index}>{rangeEnd}+</p>;
    }
    return <p key={index}>{breakpoint}</p>;
  });

  return (
    <div className="IndiaMapColorCoder">
      <div className={`colors crime`}></div>
      <div className="numbers">{breakpointElements}</div>
    </div>
  );
}
export default IndiaMapColorCoder;
