import React, { useState, useEffect, useCallback, useMemo } from "react";
import { Space } from "antd";
import "./style.scss";
import SearchIcon from "../../Assets/Search.svg";
import GraphContainer from "./GraphContainer";
import { ReactComponent as TriangleRightIcon } from "../../Assets/triangleRight.svg";
import AppHeader from "../../Components/AppHeader";
import RecentPosts from "../../Components/RecentPosts";
import IndiaMap from "../../Components/Graph/IndiaMap";
import Loader from "../../Components/Loader";
import { commonService } from "../../API";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import AppFooter from "../../Components/AppFooter";
import IndiaMapColorCoder from "../../Components/Graph/IndiaMapColorCoder";
import { colorsArray } from "../../Constants/mockData";
import Helmet from "react-helmet";

function Home() {
  const [loading, setLoading] = useState(false);
  const [newsList, setNewsList] = useState([]);
  const [reportsList, setReportsList] = useState([]);
  const [dashboardCount, setDashboardCount] = useState({});
  const [graphData, setGraphData] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [indiaMapData, setIndiaMapData] = useState([]);
  const [stateName, setStateName] = useState("");
  const [stateValue, setStateValue] = useState("");
  const [category, setCategory] = useState("Crimes");
  const navigate = useNavigate();

  const fetchData = useCallback(async () => {
    setLoading(true);
    try {
      const payload = { page: 0, limit: 4 };
      const [
        newsResponse,
        reportsResponse,
        dashboardCountResponse,
        graphDataResponse,
        indiaMapDataResponse,
      ] = await Promise.all([
        commonService.getNewsList(payload),
        commonService.getReportsList(payload),
        commonService.getDashboardCount(),
        commonService.getGraphData(),
        commonService.getIndiaMapData(),
      ]);
      setNewsList(newsResponse.data.news || []);
      setReportsList(reportsResponse.data.reports || []);
      setDashboardCount(dashboardCountResponse.data || {});
      setGraphData(graphDataResponse.data.result || []);
      setIndiaMapData(updatedData(indiaMapDataResponse.data.data || [], category));
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, [category]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const setCardCategory = useCallback((getCategory, path) => {
    setCategory(getCategory);
    navigate(path);
  }, [navigate]);

  const onStateHover = useCallback((stateName, stateId, stateValue) => {
    setStateName(stateName || "India");
    setStateValue(stateValue || 0);
  }, []);

  const onInputChange = useCallback((e) => {
    setSearchInput(e.target.value);
  }, []);

  const getColor = useCallback((value) => {
    const divisionSize = 1000 / 20;
    const index = Math.floor(value / divisionSize);
    const reversedColorArray = [...colorsArray].reverse();
    return `#${reversedColorArray[index]}`;
  }, []);

  const updatedData = useCallback((data) => {
    return data.map((item) => ({
      ...item,
      color: getColor(item.value),
    }));
  }, [getColor]);

  const sum = useMemo(() => {
    return indiaMapData.reduce((accumulator, item) => accumulator + item.value, 0);
  }, [indiaMapData]);

  return (
    <Space size={20} direction="vertical">
      {loading ? (
        <Loader />
      ) : (
        <div className="home">
          <AppHeader />
          <div className="home_wrapper">
            <Helmet>
              <title>Home - Radical Watch</title>
            </Helmet>
            <div className="home_left_wrapper">
              <div className="search_container font-face-Medium">
                <h1>Search your district or state</h1>
                <div className="search">
                  <div className="icon">
                    <img src={SearchIcon} alt="Search Icon" />
                  </div>
                  <input
                    className="input"
                    placeholder="West Bengal"
                    name="search"
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        navigate({
                          pathname: "/related-search",
                          search: `search=${searchInput}`,
                        });
                      }
                    }}
                    onChange={onInputChange}
                  />
                </div>
              </div>
              {graphData && (
                <GraphContainer
                  data={dashboardCount}
                  graphData={graphData}
                  category={category}
                  setCardCategory={setCardCategory}
                />
              )}
            </div>
            <div className="home_right_wrapper">
              <div className="indiaMap_header">
                <div className="left_header">
                  <div className="indiaHeading">
                    <p>India</p>
                  </div>
                  <p className="date">
                    {`Last Updated on
                    ${moment()
                      .utcOffset("+05:30")
                      .format("DD MMM, h:mm A")} IST`}
                  </p>
                </div>
                <div className={`right_header`}>
                  <p className="stateName">{stateName || "India"}</p>
                  <p className="stateValue">
                    {stateValue?.toLocaleString() || sum?.toLocaleString()}
                  </p>
                  <div className="crimes">
                    <TriangleRightIcon />
                    <div className="content">
                      <p>{"Crimes"}</p>
                    </div>
                  </div>
                </div>
              </div>
              <IndiaMap
                data={indiaMapData}
                onStateHover={onStateHover}
                category={category}
              />
              <IndiaMapColorCoder category={category} />
            </div>
          </div>
          <RecentPosts heading="News" path="/news" data={newsList} />
          <RecentPosts heading="Reports" path="/reports" data={reportsList} />
          <AppFooter />
        </div>
      )}
    </Space>
  );
}

export default Home;
