import React, { useEffect, useRef } from "react";
import Chart from "chart.js/auto";

const Spline = (props) => {
  const splineChartRef = useRef(null);
  const { data } = props;
  useEffect(() => {
    let splineChart;
    // Spline Chart
    const splineChartCtx = splineChartRef.current.getContext("2d");
    splineChart = new Chart(splineChartCtx, {
      type: "line",
      width: "100%",
      data: data,
      options: {
        responsive: true,
        plugins: {
          legend: {
            display: false,
          },
          title: {
            display: false,
          },
          tooltip: {
            enabled: true, // Enable tooltips
          },
        },
        scales: {
          x: {
            display: false,
            grid: {
              display: false,
            },
            ticks: {
              display: false,
            },
          },
          y: {
            display: false,
            grid: {
              display: false,
            },
            ticks: {
              display: false,
            },
          },
        },
      },
    });

    return () => {
      splineChart.destroy();
    };
  }, []);

  return (
    <div className="splineChart">
      <canvas ref={splineChartRef} />
    </div>
  );
};

export default Spline;
