import React, { useState, useEffect } from "react";
import "./style.scss";
import { Input, Space } from "antd";
import { ReactComponent as SearchBlackIcon } from "../../Assets/SearchBlackIcon.svg";
import { ReactComponent as SearchIcon } from "../../Assets/Search.svg";
import AppHeader from "../../Components/AppHeader";
import Loader from "../../Components/Loader";
import { useNavigate } from "react-router-dom";
import { commonService } from "../../API";
import AppFooter from "../../Components/AppFooter";
function SearchPage() {
  const [loading, setLoading] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [searchData, setSearhData] = useState([]);
  const fetchData = async () => {
    const url = new URLSearchParams(window.location.search)?.get("search");
    setSearchValue(url);
    setLoading(true);
    const payload = {
      page: 0,
      limit: 20,
      search: url,
    };
    const getNewsList = await commonService.getNewsList(payload);
    setSearhData(getNewsList?.data);
    setLoading(false);
  };
  useEffect(() => {
    try {
      fetchData();
    } catch {
      setLoading(false);
    }
  }, []);
  const navigate = useNavigate();
  const onInputChange = (e) => {
    navigate({
      search: `search=${e.target.value}`,
    });
    fetchData();
  };
  return (
    <Space size={20} direction="vertical">
      {loading ? (
        <Loader />
      ) : (
        <div className="home" id="searchPage">
          <AppHeader />
          <div className="searchPageContainer">
            <div className="home_wrapper">
              <div className="searchHeader">
                <div className="searchIconContainer">
                  <SearchBlackIcon className="searchIcon" />
                </div>
                <p className="searchText">{searchValue}</p>
              </div>
            </div>
            <div className="searchContainer">
              <Input
                placeholder="Search by the Name of the Victim / Title of the Incident"
                suffix={<SearchIcon />}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    onInputChange(e);
                  }
                }}
              />
            </div>
            {searchData?.news?.length ? (
              <div>
                {searchData?.news?.map((item) => {
                  return (
                    <div
                      className="searchResult"
                      onClick={() => {
                        navigate(`/news/detail/${item?._id}`);
                      }}
                    >
                      <div className="imageContainer">
                        <img src={item.logo} />
                      </div>
                      <div className="textContainer">
                        <p className="title">{item.title}</p>
                        <p className="date">{item.createdAt}</p>
                        <p className="description">
                          {item.description.substring(0, 200) + "..."}
                        </p>
                      </div>
                    </div>
                  );
                })}
              </div>
            ) : (
              <div className="no_data_found">
                <p>No Data Found</p>
              </div>
            )}
          </div>
          <AppFooter />
        </div>
      )}
    </Space>
  );
}
export default SearchPage;
