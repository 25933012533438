import { Space } from "antd";
import React from "react";
import { Helmet } from "react-helmet";
import AppFooter from "../../Components/AppFooter";
import AppHeader from "../../Components/AppHeader";
import "./style.scss";
// import Loader from "../../Components/Loader";
export default function SupportUs() {
  return (
    <Space size={20} direction="vertical">
      
      <AppHeader />
      <div className="about-us">
      <Helmet>
        <title>Support Us - Radical Watch </title>
      </Helmet>
        <div className="heading_wrapper font-face-Bold">
          <p> Support Us</p>
        </div>
        <div className="about-content">
          {" "}
          <p>
            Radical Watch believes in a world free from violence and injustice,
            and we're striving to achieve this vision every day. As an
            independent research initiative, we are dedicated to exposing the
            ideologies, institutions, and individuals responsible for disturbing
            acts of violence, whether occurring within India or orchestrated
            from elsewhere.
          </p>
          <br />{" "}
          <p>
            However, our journey is not one we can take alone. We need your
            support to continue our vital work.
          </p>
          <br />
          <p>
            By making a donation, you are not just funding our operations. You
            are standing up against hate, violence, and injustice. Your generous
            contributions help us maintain our database, support our network of
            journalists and activists, and, most importantly, ensure our
            research remains accessible to all.
          </p>
        </div>
      </div>
      <AppFooter />
    </Space>
  );
}
