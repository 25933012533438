export const ladakhMapFeatures = {
  type: "Feature",
  properties: {
    name: "Ladakh",
    id: "IN-LA",
    TYPE: "Union Territory",
    CNTRY: "India",
    color: "#FF5733",
  },
  id: "IN-LA",
  geometry: {
    type: "Polygon",
    coordinates: [
      [
        [76.79483099050624, 33.255152656154436],
        [76.39661654188745, 33.66695329483542],
        [76.25037959747942, 33.60812463216671],
        [76.05689687103188, 33.98630889217985],
        [75.9421571146502, 33.98840991584659],
        [75.74192499076844, 34.19851228252056],
        [75.4337025079392, 34.349785986525816],
        [75.40220531991287, 34.54518118753261],
        [75.34821014043914, 34.55778732953305],
        [75.22672098662323, 34.6502323708696],
        [75.1614768114258, 34.86243576121031],
        [74.92749770037295, 34.814112216875294],
        [74.6350238115569, 34.94017363687968],
        [74.52028405517522, 35.106154506552116],
        [74.09957161510904, 35.141871908886685],
        [73.77560053826664, 35.23641797388997],
        [73.68785837162183, 35.305751754892384],
        [73.75760214510873, 35.526359239900046],
        [73.50337484175324, 35.53266231090027],
        [73.28964392300304, 35.62300632857008],
        [73.1366575811608, 35.715451369906624],
        [73.08491220083181, 35.87723019224558],
        [72.79243831201575, 35.83731074257753],
        [72.51571301721286, 35.904543499913196],
        [72.58770658984452, 36.013796730583664],
        [72.56520859839712, 36.23020216825785],
        [72.97917164102908, 36.47181988993292],
        [73.09391139741076, 36.60418438093751],
        [73.07816280339759, 36.700831469607536],
        [73.80259812800351, 36.72814477727516],
        [73.87009210234568, 36.784872416277125],
        [73.71935555964818, 36.8521051736128],
        [74.09507201681957, 36.831094936945405],
        [74.11532020912222, 36.889923599614114],
        [74.67776999530695, 37.05170242195307],
        [74.89150091405713, 36.92143895461521],
        [75.10073223451785, 36.9949747829511],
        [75.35045993958387, 36.915135883614994],
        [75.41120451649182, 36.747053990275816],
        [75.57094025576828, 36.76596320327647],
        [75.7914205719527, 36.6882253276071],
        [75.98040370011076, 36.4445065822653],
        [76.66884223840086, 36.1902827185898],
        [76.83982697340102, 35.86042200291166],
        [77.08955467846704, 35.740663653907504],
        [77.348281580112, 35.7196534172401],
        [77.51926631511216, 35.48854081389874],
        [77.90623176800725, 35.46963160089808],
        [77.9669763449152, 35.574682784235065],
        [78.13796107991536, 35.53896538190049],
        [78.27294902859968, 35.72175444090684],
        [78.4236855712972, 35.78478515090903],
        [78.94113937458714, 35.879331215912316],
        [79.11662370787677, 35.83731074257753],
        [79.24261245998215, 35.986483422916045],
        [79.38659960524544, 35.990685470249524],
        [79.424846190706, 35.8940383815795],
        [79.74656746840367, 35.799492316576206],
        [79.99854497261441, 35.83731074257753],
        [80.11553452814084, 35.67763294390531],
        [80.29326866057521, 35.60199609190268],
        [80.32926544689104, 35.47383364823156],
        [80.06828874610133, 34.71536410453852],
        [79.77356505814053, 34.62291906320198],
        [79.79381325044318, 34.484251501197164],
        [79.58008233169298, 34.48845354853064],
        [79.58458192998246, 34.238431732188616],
        [79.37535060952175, 34.03463243651487],
        [79.01538274636353, 34.051440625848784],
        [78.90514258827132, 33.97580377384615],
        [79.10537471215308, 33.614427703166925],
        [78.91864138313974, 33.63333691616758],
        [78.99288475491613, 33.33078950815707],
        [79.4540935795876, 33.259354703487915],
        [79.3843498061007, 32.97361548481132],
        [79.6273281137325, 32.738300834136474],
        [79.44734418215339, 32.53240051479598],
        [79.33935382320593, 32.5912291774647],
        [79.11437390873203, 32.383227834457465],
        [78.96138756688978, 32.36641964512354],
        [78.82639961820546, 32.439955473459435],
        [78.74990644728433, 32.681573195134504],
        [78.61941809688948, 32.595431224798176],
        [78.44393376359984, 32.5702189407973],
        [78.32694420807343, 32.47147082846053],
        [78.31569521234972, 32.574420988130775],
        [78.40568717813929, 32.61854248513231],
        [78.31344541320499, 32.708886502802116],
        [77.99397393465206, 32.58912815379796],
        [77.73074743471761, 32.96941343747784],
        [77.33928238353305, 32.82024075713932],
        [77.15479885366446, 32.9778175321448],
        [76.93431853748005, 33.032444147480035],
        [76.79483099050624, 33.255152656154436],
      ],
    ],
  },
};

export const jammuAndKashmirCoordinates=[
    [
      [74.09957161510904, 35.141871908886685],
      [74.52028405517522, 35.106154506552116],
      [74.6350238115569, 34.94017363687968],
      [74.92749770037295, 34.814112216875294],
      [75.1614768114258, 34.86243576121031],
      [75.22672098662323, 34.6502323708696],
      [75.34821014043914, 34.55778732953305],
      [75.40220531991287, 34.54518118753261],
      [75.4337025079392, 34.349785986525816],
      [75.74192499076844, 34.19851228252056],
      [75.9421571146502, 33.98840991584659],
      [76.05689687103188, 33.98630889217985],
      [76.25037959747942, 33.60812463216671],
      [76.39661654188745, 33.66695329483542],
      [76.79483099050624, 33.255152656154436],
      [76.74758520846672, 33.179515804151805],
      [76.40111614017692, 33.18371785148529],
      [76.25487919576891, 33.032444147480035],
      [76.11089205050561, 33.00302981614568],
      [76.03439887958449, 32.91688784580935],
      [75.95115631122916, 32.88537249080825],
      [75.82516755912377, 32.929493987809785],
      [75.93990731550545, 32.64375476913319],
      [75.72617639675526, 32.4168442131253],
      [75.48319808912346, 32.31809610078854],
      [75.12997962339946, 32.406339094791605],
      [75.03323826017568, 32.49248106512793],
      [74.94549609353086, 32.44415752079291],
      [74.68226959359642, 32.486177994127715],
      [74.617025418399, 32.755109023470396],
      [74.38304630734615, 32.757210047137136],
      [73.63161339300336, 33.08707076281526],
      [73.65861098274023, 33.204728088152685],
      [73.55961982037171, 33.36860793415838],
      [73.62036439727966, 33.578710300832356],
      [73.58661741010857, 33.90016692184352],
      [73.39988408109525, 34.36029110485952],
      [73.44712986313476, 34.57459551886697],
      [73.61811459813492, 34.58720166086741],
      [73.77335073912191, 34.79730402754137],
      [74.02982784162214, 34.87714292687748],
      [74.09957161510904, 35.141871908886685],
    ],
  ]

 export function updateIndiaHigh(indiaHigh) {
    const updatedFeatures = [...indiaHigh.features];
  
    const featureToUpdate = updatedFeatures.find(
      (feature) => feature.properties.name === "Jammu and Kashmir"
    );
  
    if (featureToUpdate) {
      featureToUpdate.geometry = {
        ...featureToUpdate.geometry,
        coordinates: jammuAndKashmirCoordinates,
      };
    }
  
    const updatedIndiaHigh = {
      ...indiaHigh,
      features: [...updatedFeatures, ladakhMapFeatures],
    };
  
    return updatedIndiaHigh;
  }
  
  