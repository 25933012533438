import React from "react";
// import { ReactComponent as Avatar } from "../../Assets/avatar.svg";
import "./style.scss";
import { ReactComponent as GlobeIcon } from "../../Assets/globe-outline.svg";
import { ReactComponent as TwitterIcon } from "../../Assets/twitter.svg";
import { ReactComponent as FacebookIcon } from "../../Assets/facebook.svg";
import { ReactComponent as LinkedInIcon } from "../../Assets/linkedin.svg";
import { ReactComponent as LinkIcon } from "../../Assets/link-outline.svg";

import LaunchIcon from "@mui/icons-material/Launch";
import { useNavigate } from "react-router-dom";
function NetworkCard(props) {
  const { heading = "", subHeading = "", logo, path, socialLinks } = props;
  const navigate = useNavigate();
  return (
    <div className="networkCard">
      <div className="imgContainer">
        <img src={logo} alt="avatar" onClick={() => navigate(path)} />
      </div>
      <div className="contentContainer">
        <p className="name font-face-Bold" onClick={() => navigate(path)}>
          {heading}
        </p>
        <p className="organisationName font-face-semiBold">{subHeading}</p>
        <div className="networkIcons">
          <a
            href={socialLinks?.website}
            target="_blank"
            rel="noopener noreferrer"
          >
            <GlobeIcon />
          </a>
          <a
            href={socialLinks?.twitter}
            target="_blank"
            rel="noopener noreferrer"
          >
            <TwitterIcon />
          </a>
          <a
            href={socialLinks?.facebook}
            target="_blank"
            rel="noopener noreferrer"
          >
            <FacebookIcon />
          </a>
          <a
            href={socialLinks?.linkedin}
            target="_blank"
            rel="noopener noreferrer"
          >
            <LinkedInIcon />
          </a>
          <a
            // href={socialLinks?.website}
            target="_blank"
            rel="noopener noreferrer"
          >
            <LinkIcon />
          </a>
        </div>
        <p className="link" onClick={() => navigate(path)}>
          <LaunchIcon />
        </p>
      </div>
    </div>
  );
}
export default NetworkCard;
