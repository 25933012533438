import React, { useEffect, useMemo } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4maps from "@amcharts/amcharts4/maps";
import indiaHigh from "@amcharts/amcharts4-geodata/indiaHigh";
import { updateIndiaHigh } from "./utils";

const IndiaMap = ({ data, onStateHover, category }) => {
  const updatedIndiaHigh = useMemo(() => updateIndiaHigh(indiaHigh), []);

  const strokeColor = useMemo(() => {
    switch (category) {
      case "Crimes":
        return { stroke: "#fff", hover: "#00401A" };
      case "Radical Individuals":
        return { stroke: "rgba(0, 123, 255, 0.125)", hover: "#007BFF" };
      case "Radical Organisations":
        return { stroke: "rgba(40, 167, 69, 0.125)", hover: "#28A745" };
      case "Radical Events":
        return { stroke: "rgba(108, 117, 125, 0.125)", hover: "#6C757D" };
      default:
        return { stroke: "#000", hover: "#000" };
    }
  }, [category]);

  useEffect(() => {
    let chart = am4core.create("chartdiv", am4maps.MapChart);
    chart.geodata = updatedIndiaHigh;
    chart.mouseWheelBehavior = "none";
    chart.seriesContainer.draggable = false;
    chart.seriesContainer.resizable = false;
    chart.chartContainer.draggable = false;
    chart.projection = new am4maps.projections.Miller();

    let polygonSeries = chart.series.push(new am4maps.MapPolygonSeries());
    polygonSeries.useGeodata = true;

    let polygonTemplate = polygonSeries.mapPolygons.template;
    polygonTemplate.fill = am4core.color("#CCCCCC");
    polygonTemplate.propertyFields.fill = "color";
    polygonTemplate.stroke = am4core.color(strokeColor.stroke);
    polygonTemplate.strokeOpacity = 1;
    polygonTemplate.strokeWidth = 0.5;
    polygonTemplate.cursorOverStyle = am4core.MouseCursorStyle.pointer;

    polygonSeries.tooltip.getFillFromObject = false;
    polygonSeries.tooltip.background.fill = am4core.color("#000000");
    polygonSeries.tooltip.background.fillOpacity = 1;
    polygonSeries.tooltip.label.fontSize = "12px";
    polygonSeries.tooltip.label.fill = am4core.color("#FFFFFF");
    polygonTemplate.tooltipText = "{name}: {value}";

    let hoverState = polygonTemplate.states.create("hover");
    hoverState.properties.stroke = am4core.color(strokeColor.hover);
    hoverState.properties.strokeWidth = 3;
    hoverState.properties.strokeOpacity = 2;

    polygonSeries.data = data;

    polygonTemplate.events.on("over", (event) => {
      const stateName = event.target.dataItem.dataContext.name;
      const stateId = event.target.dataItem.dataContext.id;
      const stateValue = event.target.dataItem.dataContext.value;
      onStateHover(stateName, stateId, stateValue);
    });

    polygonTemplate.events.on("hit", (event) => {
      const stateName = event.target.dataItem.dataContext.name;
      const stateId = event.target.dataItem.dataContext.id;
      const stateValue = event.target.dataItem.dataContext.value;
      onStateHover(stateName, stateId, stateValue);
    });

    const waitForElement = () => {
      const element = document.querySelector(
        "#chartdiv g[aria-labelledby^='id-'][aria-labelledby$='-title']"
      );

      if (element) {
        element.style.display = "none";
      } else {
        setTimeout(waitForElement, 100);
      }
    };
    waitForElement();

    return () => {
      chart.dispose();
    };
  }, [data, onStateHover, strokeColor, updatedIndiaHigh]);

  return <div id="chartdiv" className="chart-container" />;
};

export default IndiaMap;
